import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaqsComponent } from './dashboard/faqs/faqs.component';
import { OffersComponent } from './dashboard/offers/offers.component';
import { PrivacyPolicyComponent } from './dashboard/privacy-policy/privacy-policy.component';
import { HomeComponent } from './home.component';
import { UsersListComponent } from './dashboard/users-list/users-list.component';
import { SpaComponent } from './dashboard/service-management/spa/spa.component';
import { RoomDiningComponent } from './dashboard/service-management/room-dining/room-dining.component';
import { TransportComponent } from './dashboard/service-management/transport/transport.component';
import { HousekeepingComponent } from './dashboard/service-management/housekeeping/housekeeping.component';
import { ConferenceRoomComponent } from './dashboard/service-management/conference-room/conference-room.component';
import { PackageComponent } from './dashboard/service-management/package/package.component';
import { ConfigurationComponent } from './dashboard/configuration/configuration.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { HotelsListComponent } from './super-admin-dashboard/hotel-management/hotels-list/hotels-list.component';
import { HotelChainsListComponent } from './super-admin-dashboard/hotel-management/hotel-chains-list/hotel-chains-list.component';
import { HotelSubchainsListComponent } from './super-admin-dashboard/hotel-management/hotel-subchains-list/hotel-subchains-list.component';
import { AdminsListComponent } from './super-admin-dashboard/admins-list/admins-list.component';
import { ServicesListComponent } from './super-admin-dashboard/services-list/services-list.component';
import { UploadedUsersComponent } from './dashboard/uploaded-users/uploaded-users.component';
import { RoomsComponent } from './dashboard/rooms-and-rates/rooms/rooms.component';
import { AboutComponent } from './dashboard/about/about.component';
import { RatingServiceComponent } from './dashboard/rating-service/rating-service.component';
import { ProfileComponent } from './profile/profile.component';
import { WebsiteManagementComponent } from './super-admin-dashboard/website-management/website-management.component';
import { GuestRequestsComponent } from './dashboard/request-management/guest-requests/guest-requests.component';
import { InternalRequestsComponent } from './dashboard/request-management/internal-requests/internal-requests.component';
import { SlaDashboardComponent } from './dashboard/sla/sla-dashboard/sla-dashboard.component';
import { SlaManagementComponent } from './dashboard/sla/sla-management/sla-management.component';
import { RoomTypeComponent } from './dashboard/rooms-and-rates/room-type/room-type.component';
import { RatePlanComponent } from './dashboard/rooms-and-rates/rate-plan/rate-plan.component';
import { BulkInventoryComponent } from './dashboard/bulk-update/bulk-inventory/bulk-inventory.component';
import { BulkRateComponent } from './dashboard/bulk-update/bulk-rate/bulk-rate.component';
import { DashboardGraphComponent } from './dashboard/dashboard-graph/dashboard-graph.component';
import { InventoryReservationsComponent } from './dashboard/reservations/inventory-reservations/inventory-reservations.component';
import { AddReservationComponent } from './dashboard/reservations/add-reservation/add-reservation.component';
import { EditReservationComponent } from './dashboard/reservations/edit-reservation/edit-reservation.component';
import { ArrivalsComponent } from './dashboard/reservations/arrivals/arrivals.component';
import { DeparturesComponent } from './dashboard/reservations/departures/departures.component';
import { CheckInComponent } from './dashboard/reservations/check-in/check-in.component';
import { CheckOutComponent } from './dashboard/reservations/check-out/check-out.component';
import { CheckoutPaymentDetailsComponent } from './dashboard/reservations/check-out/checkout-payment-details/checkout-payment-details.component';
import { RoomInventoryComponent } from './dashboard/room-inventory/room-inventory.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'hotel-chains',
        canActivate: [AuthGuardService],
        data: {
          role: [1]
        },
        component: HotelChainsListComponent
      },
      {
        path: 'hotel-sub-chains',
        canActivate: [AuthGuardService],
        data: {
          role: [1]
        },
        component: HotelSubchainsListComponent
      },
      {
        path: 'hotels',
        canActivate: [AuthGuardService],
        data: {
          role: [1]
        },
        component: HotelsListComponent
      },
      {
        path: 'admins',
        canActivate: [AuthGuardService],
        data: {
          role: [1]
        },
        component: AdminsListComponent
      },
      {
        path: 'services',
        canActivate: [AuthGuardService],
        data: {
          role: [1]
        },
        component: ServicesListComponent
      },
      {
        path: 'website',
        canActivate: [AuthGuardService],
        data: {
          role: [1]
        },
        component: WebsiteManagementComponent
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: DashboardGraphComponent
      },
      {
        path: 'members',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: UsersListComponent
      },
      {
        path: 'configuration',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: ConfigurationComponent
      },
      {
        path: 'room-type',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: RoomTypeComponent
      },
      {
        path: 'rate-plan',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: RatePlanComponent
      },
      {
        path: 'bulk-inventory-update',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: BulkInventoryComponent
      },
      {
        path: 'bulk-rate-update',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: BulkRateComponent
      },
      {
        path: 'room-inventory',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: RoomInventoryComponent
      },
      {
        path: 'reservation',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: AddReservationComponent
      },
      {
        path: 'edit-reservation',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: EditReservationComponent
      },
      {
        path: 'all-reservations',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: InventoryReservationsComponent
      },
      {
        path: 'arrivals',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: ArrivalsComponent
      },
      {
        path: 'departures',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: DeparturesComponent
      },
      {
        path: 'payment-details',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: CheckoutPaymentDetailsComponent
      },
      {
        path: 'check-in',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: CheckInComponent
      },
      {
        path: 'check-out',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: CheckOutComponent
      },
      {
        path: 'spa',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: SpaComponent
      },
      {
        path: 'transport',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: TransportComponent
      },
      {
        path: 'conference-room',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: ConferenceRoomComponent
      },
      {
        path: 'package',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: PackageComponent
      },
      {
        path: 'housekeeping',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: HousekeepingComponent
      },
      {
        path: 'dining',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: RoomDiningComponent
      },
      {
        path: 'rooms',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: RoomsComponent
      },
      {
        path: 'about',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: AboutComponent
      },
      {
        path: 'guest-management',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: UploadedUsersComponent
      },
      {
        path: 'guest-request',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3, 4]
        },
        component: GuestRequestsComponent
      },
      {
        path: 'internal-request',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3, 4]
        },
        component: InternalRequestsComponent
      },
      {
        path: 'sla-dashboard',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: SlaDashboardComponent
      },
      {
        path: 'sla-management',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: SlaManagementComponent
      },
      {
        path: 'privacy-policy',
        canActivate: [AuthGuardService],
        data: {
          role: [2]
        },
        component: PrivacyPolicyComponent
      },
      {
        path: 'offers',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: OffersComponent
      },
      {
        path: 'faq',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: FaqsComponent
      },
      {
        path: 'rating',
        canActivate: [AuthGuardService],
        data: {
          role: [2, 3]
        },
        component: RatingServiceComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {
  public static routes = routes;
}