import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-check-out-card',
  templateUrl: './check-out-card.component.html',
  styleUrls: ['./check-out-card.component.scss']
})
export class CheckOutCardComponent {

  hotelName: string;
  imageURL: string;
  public bucket_url = `${environment.BUCKET_URL}`;
  loading: boolean = true;
  reservationDetails: any = {};
  currentDate = new Date();
  currencyInfo: any;
  currency: any;
  displayedServiceColumns: string[] = ['date', 'service', 'quantity', 'amount', 'discount', 'tax', 'total'];
  displayedRoomColumns: string[] = ['days', 'adult', 'child', 'tax', 'total'];
  serviceDataSource = new MatTableDataSource<any>();
  roomDataSource = new MatTableDataSource<any>();
  noOfDays: number;
  roomsTotalAmount: number = 0;
  servicesTotalAmount: number = 0;
  totalServiceTax: number = 0;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<CheckOutCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public notify: NotificationService,
    private hotelService: HotelServicesService
  ) { }

  ngOnInit() {
    this.hotelName = window.localStorage.getItem('hotel_name');
    this.imageURL = this.bucket_url + window.localStorage.getItem('hotel_icon');
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currency = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.checkScreenWidth();
    this.getPaymentDetails();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close();
    } else {
      this.mobile = false;
    }
  }

  getPaymentDetails() {
    this.hotelService.getReservationPaymentInfo(this.data.id, this.data.bookingId).subscribe({
      next: (data) => {
        this.loading = false;
        this.reservationDetails = data.object;
        const checkInDate = moment(this.reservationDetails.ROOM_PAYMENT?.BOOKING?.FROM_DATE, 'DD-MM-YYYY').format('MM-DD-YYYY');
        const checkOutDate = moment(this.reservationDetails.ROOM_PAYMENT?.BOOKING?.TO_DATE, 'DD-MM-YYYY').format('MM-DD-YYYY');
        this.noOfDays = this.calculateDateDiff(checkInDate, checkOutDate);
        let securityDeposit: number = 0;
        if (!!this.reservationDetails.ROOM_PAYMENT?.ROOMRESERVATION) {
          this.reservationDetails.ROOM_PAYMENT?.ROOMRESERVATION.forEach(room => {
            securityDeposit += room.RATE_PLAN_ID?.CHECKIN_DEPOSIT_AMOUNT;
          })
          this.reservationDetails.ROOM_PAYMENT.SECURITY_DEPOSITE = securityDeposit || 0;
        }
        let item = data.object.ROOM_PAYMENT?.ROOMRESERVATION[0];
        if (!!item) {
          item.RATE_PLAN_ID.RATE_PLAN_APPLICABLE = {};
          for (let rate of item?.RATE_PLAN_ID?.RATE_PLAN_PRICING) {
            let retValue: boolean = false;
            retValue = (new Date(checkInDate) >= new Date(rate.DATE_FROM)) && (new Date(checkOutDate) <= new Date(rate.DATE_TO));
            if (retValue == true) {
              item.RATE_PLAN_ID.RATE_PLAN_APPLICABLE = rate;
            }
          }
          if (Object.keys(item.RATE_PLAN_ID?.RATE_PLAN_APPLICABLE).length != 0) {
            Object.keys(item.RATE_PLAN_ID?.RATE_PLAN_APPLICABLE?.OCCUPANT).forEach(key => {
              if (key == this.reservationDetails?.ROOM_PAYMENT?.BOOKING?.ADULT_OCCUPANTS) {
                for (let i = 0; i < this.noOfDays; i++) {
                  let obj = {
                    ADULT_AMOUNT: item.RATE_PLAN_ID?.RATE_PLAN_APPLICABLE?.OCCUPANT[key] * data.object.ROOM_PAYMENT?.BOOKING?.ROOMS || 0,
                    CHILD_AMOUNT: 0,
                    TAX_AMOUNT: 0,
                    TOTAL_AMOUNT: item.RATE_PLAN_ID?.RATE_PLAN_APPLICABLE?.OCCUPANT[key] * data.object.ROOM_PAYMENT?.BOOKING?.ROOMS || 0
                  }
                  this.roomDataSource.data.push(obj);
                }
              }
            })
          }
        }
        this.roomDataSource = new MatTableDataSource<any>(this.roomDataSource.data);
        this.roomsTotalAmount = this.roomDataSource.data.reduce((roomTotal: any, value: any) => roomTotal + value?.TOTAL_AMOUNT, 0);
        this.roomsTotalAmount = parseFloat(this.roomsTotalAmount.toFixed(2));
        this.serviceDataSource.data = data.object.SERVICES_PAYMENT;
        this.serviceDataSource = new MatTableDataSource<any>(this.serviceDataSource.data);
        for (let bs_i = 0; bs_i < this.serviceDataSource.data.length; bs_i++) {
          let item = this.serviceDataSource.data[bs_i];
          let serviceID = item.SERVICE_ID.ID;
          let offerValue = item.OFFER_ID;
          if (serviceID === 2) return;
          let categoryPrice = item.CATEGORY_TYPE_ID?.PRICE;
          item.totalItemsPrice = categoryPrice || 0;
          if (serviceID === 6) {
            item.totalItemsPrice = this.calDiningTotalItemsPrice(item.ITEMS);
            item.OFFER_PRICE = this.calDiningOfferPrice(item);
          }
          if (offerValue && serviceID !== 6) {
            let offerPrice = Math.round(((offerValue?.DISCOUNT / 100) * categoryPrice) * 100) / 100;
            item.OFFER_PRICE = offerPrice;
            let priceAfterOffers = Math.round((categoryPrice - offerPrice) * 100) / 100;
            item.PRICE_AFTER_OFFERS = priceAfterOffers;
          }
          this.calculateTotalPrice(serviceID, bs_i);
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  calculateDateDiff(checkInDate, checkOutDate) {
    checkInDate = new Date(checkInDate);
    checkOutDate = new Date(checkOutDate);
    return Math.floor((Date.UTC(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate()) - Date.UTC(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate())) / (1000 * 60 * 60 * 24));
  }

  calDiningTotalItemsPrice(itemArrays = []) {
    const totalItemPrice = itemArrays.reduce((acc, cur: any) => {
      return acc + Number(cur?.CATEGORY_TYPE_ID?.PRICE || 0) * (cur?.QUANTITY || 1);
    }, 0)
    return totalItemPrice;
  }

  calDiningOfferPrice(item) {
    let dinItemsPrice = 0;
    if (item.OFFER_ID !== null) {
      let offerPrice = Math.round(((item.OFFER_ID?.DISCOUNT / 100) * item?.totalItemsPrice) * 100) / 100;
      dinItemsPrice = offerPrice;
    }
    return dinItemsPrice;
  }

  calculateTotalPrice(serviceID, indx) {
    let totalPrice = parseFloat(this.serviceDataSource.data[indx]?.CATEGORY_TYPE_ID?.PRICE);
    if (serviceID === 6) {
      totalPrice = this.calDiningTotalItemsPrice(this.serviceDataSource.data[indx]?.ITEMS);
      this.calcDiningTaxAmount(this.serviceDataSource.data[indx]?.ITEMS, this.serviceDataSource.data[indx].OFFER_ID);
      let totalDiningTax = 0;
      for (let item of this.serviceDataSource.data[indx]?.ITEMS) {
        if (!!item?.TAX_AMOUNT) {
          totalDiningTax += item?.TAX_AMOUNT;
        }
      }
      this.serviceDataSource.data[indx].TAX_AMOUNT = parseFloat(totalDiningTax.toFixed(2));
    }
    if (serviceID !== 6 && serviceID !== 2) {
      this.calcServiceTaxAmount(this.serviceDataSource.data[indx]);
    }
    if (this.serviceDataSource.data[indx].OFFER_ID !== null && serviceID !== 2) {
      const offerDiscount = this.serviceDataSource.data[indx]?.OFFER_ID?.DISCOUNT;
      const discountedPrice = totalPrice - (totalPrice * (offerDiscount / 100));
      totalPrice = Math.round(discountedPrice * 100) / 100;
    }
    this.serviceDataSource.data[indx].TOTAL_AMOUNT = totalPrice;
    this.servicesTotalAmount += totalPrice;
    this.servicesTotalAmount = parseFloat(this.servicesTotalAmount.toFixed(2));
  }

  calcDiningTaxAmount(itemArrays: any = [], offer) {
    let totalTaxAmount = 0;
    for (let item of itemArrays) {
      if (item?.CATEGORY_TYPE_ID?.SERVICETAX.length != 0) {
        let dinItemsPrice = 0;
        if (offer !== null) {
          let offerPrice = Math.round(((offer?.DISCOUNT / 100) * (item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY)) * 100) / 100;
          dinItemsPrice = offerPrice;
          for (let tax of item?.CATEGORY_TYPE_ID?.SERVICETAX) {
            totalTaxAmount += Number(((item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY) - dinItemsPrice) * (tax.TAX_ID.PERCENTAGE / 100));
          }
          item.TAX_AMOUNT = parseFloat(totalTaxAmount.toFixed(2));
        }
        else {
          for (let tax of item?.CATEGORY_TYPE_ID?.SERVICETAX) {
            totalTaxAmount += Number((item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY) * (tax.TAX_ID.PERCENTAGE / 100));
          }
          item.TAX_AMOUNT = parseFloat(totalTaxAmount.toFixed(2));
        }
      }
    }
    this.totalServiceTax += totalTaxAmount;
    this.totalServiceTax = parseFloat(this.totalServiceTax.toFixed(2));
  }

  calcServiceTaxAmount(item) {
    let totalTaxAmount = 0;
    if (item?.CATEGORY_TYPE_ID?.SERVICETAX.length != 0) {
      for (let tax of item?.CATEGORY_TYPE_ID?.SERVICETAX) {
        if (item.OFFER_PRICE != null) {
          totalTaxAmount += Number(((item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY) - item.OFFER_PRICE) * (tax.TAX_ID.PERCENTAGE / 100));
        }
        else {
          totalTaxAmount += Number((item?.CATEGORY_TYPE_ID?.PRICE * item?.QUANTITY) * (tax.TAX_ID.PERCENTAGE / 100));
        }
      }
      item.TAX_AMOUNT = parseFloat(totalTaxAmount.toFixed(2));
    }
    this.totalServiceTax += totalTaxAmount;
    this.totalServiceTax = parseFloat(this.totalServiceTax.toFixed(2));
  }

  printReceipt() {
    window.print();
  }

  closeDialog() {
    this.dialogRef.close('Closed');
  }

}