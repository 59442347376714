import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';
import { PackageBannersComponent } from './package-banners/package-banners.component';
import { PackageCategoriesComponent } from './package-categories/package-categories.component';
import { PackageTypesComponent } from './package-types/package-types.component';
import { PackageCalendarComponent } from './package-calendar/package-calendar.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Router } from '@angular/router';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent {

  displayedColumns: string[] = ['sr.no', 'image', 'name', 'tags', 'price', 'description', 't_c', 'action'];
  dataSource = new MatTableDataSource<any>();
  currentActive = 0;
  currentCategoryID: Number;
  packageBanners = [];
  packageCategories = [];
  packageTypes = [];
  deleteBannerBody = {};
  deleteCategoryBody = {};
  public bucketUrl = `${environment.BUCKET_URL}`;
  loading: boolean = true;
  serviceTiming = [];
  userInfo: any;
  userName: any;
  accessType: any;
  currencyInfo: any;
  currency: any;
  confirmationMsg: any = {};
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  @ViewChild('bannerContent', {}) public bannerContent;
  @ViewChild('categoryContent', {}) public categoryContent;

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    public hotelService: HotelServicesService,
    private cs: ConfigurationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currency = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.getBanners();
    this.getCategories();
    this.cs.getServiceTimings().subscribe({
      next: (services) => {
        this.serviceTiming = services.object;
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getBanners() {
    this.hotelService.getPackageBanner().subscribe({
      next: (data) => {
        this.loading = false;
        this.packageBanners = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addBanners() {
    const dialogRef = this.dialog.open(PackageBannersComponent, {
      width: '558px',
      height: 'auto',
      data: { name: "add_banner" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getBanners();
      }
    });
  }

  updateBanner(id) {
    const dialogRef = this.dialog.open(PackageBannersComponent, {
      width: '558px',
      height: 'auto',
      data: { ID: id, name: "update_banner" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getBanners();
      }
    });
  }

  deleteBanner(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this banner ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.deleteBannerBody = {
          "ID": id,
          "DELETED": true
        }
        this.hotelService.updatePackageBanner(this.deleteBannerBody).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Document deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.getBanners();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  getCategories() {
    this.hotelService.getPackageCategory().subscribe({
      next: (data) => {
        this.packageCategories = data.object;
        for (let i = 0; i < this.packageCategories.length; i++) {
          this.currentActive = 0;
          this.currentCategoryID = this.packageCategories[0].ID;
        }
        this.loading = false;
        if (this.packageCategories.length != 0) {
          this.getTypes(this.currentCategoryID);
        }
        else {
          this.dataSource.data = [];
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addCategories() {
    const dialogRef = this.dialog.open(PackageCategoriesComponent, {
      width: '600px',
      height: 'auto',
      data: { name: "add_category" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getCategories();
      }
    });
  }

  updateCategory(id) {
    const dialogRef = this.dialog.open(PackageCategoriesComponent, {
      width: '600px',
      height: 'auto',
      data: { ID: id, name: "update_category" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getCategories();
      }
    });
  }

  deleteCategory(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this category ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.deleteCategoryBody = {
          "ID": id,
          "DELETED": true
        }
        this.hotelService.updatePackageCategory(this.deleteCategoryBody).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Document deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.getCategories();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  getTypes(id) {
    this.hotelService.getPackageType(id).subscribe({
      next: (data) => {
        this.loading = false;
        this.dataSource.data = data.object;
        this.packageTypes = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addTypes() {
    const dialogRef = this.dialog.open(PackageTypesComponent, {
      width: '1200px',
      height: 'auto',
      data: { categoryID: this.currentCategoryID, name: "add_type" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getTypes(this.currentCategoryID);
      }
    });
  }

  editTypes(id) {
    const dialogRef = this.dialog.open(PackageTypesComponent, {
      width: '1200px',
      height: 'auto',
      data: { typeID: id, name: "edit_type" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getTypes(this.currentCategoryID);
      }
    });
  }

  deleteTypes(id, category_id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this type ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        let deleteTypeBody = {
          "ID": id,
          "CATEGORY_ID": category_id,
          "DELETED": true
        }
        this.hotelService.updatePackageType(deleteTypeBody).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Package type deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.getTypes(category_id);
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

  openPackageCalendar(typeId, categoryId) {
    const dialogRef = this.dialog.open(PackageCalendarComponent, {
      width: '795px',
      height: 'auto',
      data: { 'TYPE_ID': typeId, 'CATEGORY_ID': categoryId },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

    })
  }

  displayDetails(index, name, id) {
    this.currentActive = index;
    this.currentCategoryID = id;
    this.getTypes(this.currentCategoryID);
  }

  public bannerScrollRight(): void {
    this.bannerContent.nativeElement.scrollTo({ left: (this.bannerContent.nativeElement.scrollLeft + 500), behavior: 'smooth' });
  }

  public bannerScrollLeft(): void {
    this.bannerContent.nativeElement.scrollTo({ left: (this.bannerContent.nativeElement.scrollLeft - 500), behavior: 'smooth' });
  }

  public categoryScrollRight(): void {
    this.categoryContent.nativeElement.scrollTo({ left: (this.categoryContent.nativeElement.scrollLeft + 500), behavior: 'smooth' });
  }

  public categoryScrollLeft(): void {
    this.categoryContent.nativeElement.scrollTo({ left: (this.categoryContent.nativeElement.scrollLeft - 500), behavior: 'smooth' });
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}