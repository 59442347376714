import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss']
})
export class AddAdminComponent {

  addUserForm: FormGroup;
  countries = [];
  accessTypes = [];
  hotelChains = [];
  hotelSubChains = [];
  clients = [];
  loading: boolean = false;
  isEnabled: boolean;
  detailsPattern = /^[a-z A-Z]+$/;
  mobilePattern = /^[0-9]{10,15}$/;
  public emailPattern = `${environment.EMAIL_PATTERN}`;
  public passwordPattern = `${environment.PASSWORD_PATTERN}`;
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddAdminComponent>,
    private fb: FormBuilder,
    private adminService: AdminService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addUserForm = this.fb.group({
      chainName: ['', Validators.required],
      subChainName: ['', Validators.required],
      client: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      mobileNumber: ['', [Validators.required, Validators.pattern(this.mobilePattern)]],
      accessType: ['', Validators.required],
      country: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    this.getChains();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.countries = res['COUNTRY'];
        this.accessTypes = res['ACCESS_TYPE'];
      }
    })
    if (this.data) {
      this.loading = true;
      this.adminService.getAdminById(this.data.ID).subscribe(res => {
        this.loading = false;
        this.isEnabled = res.object.USER_DISABLED;
        // this.addUserForm.get("chainName").setValue(res.object.TENANT.ID);
        // this.addUserForm.get("subChainName").setValue(res.object.TENANT.ID);
        this.addUserForm.get("client").setValue(res.object.TENANT.ID);
        this.addUserForm.get("firstName").setValue(res.object.FIRST_NAME);
        this.addUserForm.get("lastName").setValue(res.object.LAST_NAME);
        this.addUserForm.get("email").setValue(res.object.EMAIL_ID);
        this.addUserForm.get("mobileNumber").setValue(res.object.MOBILE);
        this.addUserForm.get("accessType").setValue(res.object.ACCESS_TYPE?.ID);
        this.addUserForm.get("country").setValue(res.object.COUNTRY?.ID);
      });
      this.addUserForm.controls['password'].removeValidators(Validators.required);
      this.addUserForm.controls['chainName'].disable();
      this.addUserForm.controls['email'].disable();
      this.addUserForm.controls['mobileNumber'].disable();
      this.addUserForm.controls['country'].disable();
    } else {
      this.addUserForm.controls['email'].enable();
      this.addUserForm.controls['mobileNumber'].enable();
      this.addUserForm.controls['country'].enable();
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  getChains() {
    this.adminService.getHotelChainsList().subscribe({
      next: (data) => {
        this.hotelChains = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectChain(chainId) {
    this.adminService.getHotelSubChainByChain(chainId).subscribe({
      next: (data) => {
        this.hotelSubChains = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectSubChain(subChainId) {
    this.adminService.getClientBySubChain(subChainId).subscribe({
      next: (data) => {
        this.clients = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  addUser() {
    this.loading = true;
    if (this.data != null) {
      let obj = {
        "ID": this.data.ID,
        "S_C_ID": this.addUserForm.value.subChainName,
        "T_ID": this.addUserForm.value.client,
        "FIRST_NAME": this.addUserForm.value.firstName,
        "LAST_NAME": this.addUserForm.value.lastName,
        "PASSWORD": this.addUserForm.value.password,
        "ACCESS_TYPE_ID": this.addUserForm.value.accessType,
        "ENABLED": !this.isEnabled
      }
      if (this.addUserForm.value.password == '') {
        delete obj['PASSWORD'];
      }
      this.adminService.updateAdmin(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "C_ID": this.addUserForm.value.chainName,
        "S_C_ID": this.addUserForm.value.subChainName,
        "T_ID": this.addUserForm.value.client,
        "EMAIL": this.addUserForm.value.email,
        "FIRST_NAME": this.addUserForm.value.firstName,
        "LAST_NAME": this.addUserForm.value.lastName,
        "PASSWORD": this.addUserForm.value.password,
        "PHONE_NUMBER": this.addUserForm.value.mobileNumber,
        "ACCESS_TYPE_ID": this.addUserForm.value.accessType,
        "COUNTRY_CODE_ID": this.addUserForm.value.country
      }
      this.adminService.createAdmin(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}