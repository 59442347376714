import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { OffersComponent } from './dashboard/offers/offers.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FaqsComponent } from './dashboard/faqs/faqs.component';
import { AddComponent } from './dashboard/offers/add/add.component';
import { AddFaqComponent } from './dashboard/faqs/add-faq/add-faq.component';
import { UsersListComponent } from './dashboard/users-list/users-list.component';
import { AddUserComponent } from './dashboard/users-list/add-user/add-user.component';
import { PrivacyPolicyComponent } from './dashboard/privacy-policy/privacy-policy.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogDeletePolicyComponent } from './dashboard/privacy-policy/dialog-delete-policy/dialog-delete-policy.component';
import { QuillModule } from 'ngx-quill';
import { MatBadgeModule } from '@angular/material/badge';
import { SpaBannersComponent } from './dashboard/service-management/spa/spa-banners/spa-banners.component';
import { SpaCategoriesComponent } from './dashboard/service-management/spa/spa-categories/spa-categories.component';
import { SpaComponent } from './dashboard/service-management/spa/spa.component';
import { SpaTypesComponent } from './dashboard/service-management/spa/spa-types/spa-types.component';
import { SpaCalendarComponent } from './dashboard/service-management/spa/spa-calendar/spa-calendar.component';
import { RoomDiningComponent } from './dashboard/service-management/room-dining/room-dining.component';
import { RoomDiningBannersComponent } from './dashboard/service-management/room-dining/room-dining-banners/room-dining-banners.component';
import { RoomDiningCategoriesComponent } from './dashboard/service-management/room-dining/room-dining-categories/room-dining-categories.component';
import { RoomDiningTypesComponent } from './dashboard/service-management/room-dining/room-dining-types/room-dining-types.component';
import { TransportComponent } from './dashboard/service-management/transport/transport.component';
import { TransportBannersComponent } from './dashboard/service-management/transport/transport-banners/transport-banners.component';
import { TransportCategoriesComponent } from './dashboard/service-management/transport/transport-categories/transport-categories.component';
import { TransportTypesComponent } from './dashboard/service-management/transport/transport-types/transport-types.component';
import { TransportCalendarComponent } from './dashboard/service-management/transport/transport-calendar/transport-calendar.component';
import { HousekeepingComponent } from './dashboard/service-management/housekeeping/housekeeping.component';
import { HousekeepingBannersComponent } from './dashboard/service-management/housekeeping/housekeeping-banners/housekeeping-banners.component';
import { HousekeepingServicesComponent } from './dashboard/service-management/housekeeping/housekeeping-services/housekeeping-services.component';
import { ConferenceRoomComponent } from './dashboard/service-management/conference-room/conference-room.component';
import { ConferenceRoomBannersComponent } from './dashboard/service-management/conference-room/conference-room-banners/conference-room-banners.component';
import { ConferenceRoomCategoriesComponent } from './dashboard/service-management/conference-room/conference-room-categories/conference-room-categories.component';
import { ConferenceRoomTypesComponent } from './dashboard/service-management/conference-room/conference-room-types/conference-room-types.component';
import { ConferenceCalendarComponent } from './dashboard/service-management/conference-room/conference-calendar/conference-calendar.component';
import { PackageComponent } from './dashboard/service-management/package/package.component';
import { PackageBannersComponent } from './dashboard/service-management/package/package-banners/package-banners.component';
import { PackageCategoriesComponent } from './dashboard/service-management/package/package-categories/package-categories.component';
import { PackageTypesComponent } from './dashboard/service-management/package/package-types/package-types.component';
import { PackageCalendarComponent } from './dashboard/service-management/package/package-calendar/package-calendar.component';
import { ConfigurationComponent } from './dashboard/configuration/configuration.component';
import { LogoutConfirmationComponent } from './dashboard/logout-confirmation/logout-confirmation.component';
import { AdminsListComponent } from './super-admin-dashboard/admins-list/admins-list.component';
import { AddAdminComponent } from './super-admin-dashboard/admins-list/add-admin/add-admin.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ServicesListComponent } from './super-admin-dashboard/services-list/services-list.component';
import { AddEditAreaComponent } from './dashboard/configuration/add-edit-area/add-edit-area.component';
import { AddEditRoomComponent } from './dashboard/configuration/add-edit-room/add-edit-room.component';
import { AddEditShiftComponent } from './dashboard/configuration/add-edit-shift/add-edit-shift.component';
import { AddEditRoomAllocationComponent } from './dashboard/configuration/add-edit-room-allocation/add-edit-room-allocation.component';
import { UploadedUsersComponent } from './dashboard/uploaded-users/uploaded-users.component';
import { UploadUsersSheetComponent } from './dashboard/uploaded-users/upload-users-sheet/upload-users-sheet.component';
import { EditServiceTimingComponent } from './dashboard/configuration/edit-service-timing/edit-service-timing.component';
import { RoomsComponent } from './dashboard/rooms-and-rates/rooms/rooms.component';
import { AboutComponent } from './dashboard/about/about.component';
import { RoomBannersComponent } from './dashboard/rooms-and-rates/rooms/room-banners/room-banners.component';
import { RoomCategoriesComponent } from './dashboard/rooms-and-rates/rooms/room-categories/room-categories.component';
import { AboutBannersComponent } from './dashboard/about/about-banners/about-banners.component';
import { AboutCategoriesComponent } from './dashboard/about/about-categories/about-categories.component';
import { AboutTypesComponent } from './dashboard/about/about-types/about-types.component';
import { AddEditDiningfilterComponent } from './super-admin-dashboard/services-list/add-edit-diningfilter/add-edit-diningfilter.component';
import { RatingServiceComponent } from './dashboard/rating-service/rating-service.component';
import { AddRatingComponent } from './dashboard/rating-service/add-rating/add-rating.component';
import { ConfirmationPopupComponent } from './dashboard/confirmation-popup/confirmation-popup.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { BookingNotificationsComponent } from './booking-notifications/booking-notifications.component';
import { AddAppUsersComponent } from './dashboard/uploaded-users/add-app-users/add-app-users.component';
import { AppQRcodeComponent } from './super-admin-dashboard/hotel-management/hotels-list/app-qrcode/app-qrcode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ProfileComponent } from './profile/profile.component';
import { RatingFilterComponent } from './dashboard/rating-service/rating-filter/rating-filter.component';
import { WebsiteManagementComponent } from './super-admin-dashboard/website-management/website-management.component';
import { AddEditTaxComponent } from './dashboard/configuration/add-edit-tax/add-edit-tax.component';
import { NgxInfiniteScrollerModule } from 'ngx-infinite-scroller';
import { GuestRequestsComponent } from './dashboard/request-management/guest-requests/guest-requests.component';
import { InternalRequestsComponent } from './dashboard/request-management/internal-requests/internal-requests.component';
import { SlaDashboardComponent } from './dashboard/sla/sla-dashboard/sla-dashboard.component';
import { SlaManagementComponent } from './dashboard/sla/sla-management/sla-management.component';
import { BookServiceComponent } from './dashboard/request-management/guest-requests/book-service/book-service.component';
import { SlotCheckPopupComponent } from './dashboard/request-management/guest-requests/slot-check-popup/slot-check-popup.component';
import { RequestConfirmedPopupComponent } from './dashboard/request-management/guest-requests/request-confirmed-popup/request-confirmed-popup.component';
import { BookDiningComponent } from './dashboard/request-management/guest-requests/book-dining/book-dining.component';
import { UpdateGuestBookingComponent } from './dashboard/request-management/guest-requests/update-guest-booking/update-guest-booking.component';
import { UpdateInternalBookingComponent } from './dashboard/request-management/internal-requests/update-internal-booking/update-internal-booking.component';
import { CsatGraphComponent } from './dashboard/sla/sla-dashboard/csat-graph/csat-graph.component';
import { CustomerSupportComponent } from './dashboard/sla/sla-dashboard/customer-support/customer-support.component';
import { SlaGraphComponent } from './dashboard/sla/sla-dashboard/sla-graph/sla-graph.component';
import { SuccessRateGraphComponent } from './dashboard/sla/sla-dashboard/success-rate-graph/success-rate-graph.component';
import { FileDragDropDirective } from '../file-drag-drop.directive';
import { RoomTypeComponent } from './dashboard/rooms-and-rates/room-type/room-type.component';
import { RatePlanComponent } from './dashboard/rooms-and-rates/rate-plan/rate-plan.component';
import { ViewRatePlansComponent } from './dashboard/rooms-and-rates/view-rate-plans/view-rate-plans.component';
import { AddEditCancellationComponent } from './dashboard/configuration/add-edit-cancellation/add-edit-cancellation.component';
import { BulkInventoryComponent } from './dashboard/bulk-update/bulk-inventory/bulk-inventory.component';
import { BulkRateComponent } from './dashboard/bulk-update/bulk-rate/bulk-rate.component';
import { DashboardGraphComponent } from './dashboard/dashboard-graph/dashboard-graph.component';
import { DashboardSlaComponent } from './dashboard/dashboard-graph/dashboard-sla/dashboard-sla.component';
import { RevenueComponent } from './dashboard/dashboard-graph/revenue/revenue.component';
import { RatingComponent } from './dashboard/dashboard-graph/rating/rating.component';
import { InventoryReservationsComponent } from './dashboard/reservations/inventory-reservations/inventory-reservations.component';
import { AddReservationComponent } from './dashboard/reservations/add-reservation/add-reservation.component';
import { EditReservationComponent } from './dashboard/reservations/edit-reservation/edit-reservation.component';
import { ArrivalsComponent } from './dashboard/reservations/arrivals/arrivals.component';
import { DeparturesComponent } from './dashboard/reservations/departures/departures.component';
import { CheckInComponent } from './dashboard/reservations/check-in/check-in.component';
import { CheckOutComponent } from './dashboard/reservations/check-out/check-out.component';
import { CheckInCardComponent } from './dashboard/reservations/check-in/check-in-card/check-in-card.component';
import { CheckOutCardComponent } from './dashboard/reservations/check-out/check-out-card/check-out-card.component';
import { CheckoutPaymentDetailsComponent } from './dashboard/reservations/check-out/checkout-payment-details/checkout-payment-details.component';
import { RoomInventoryComponent } from './dashboard/room-inventory/room-inventory.component';
import { AddEditMarketSegmentComponent } from './dashboard/configuration/add-edit-market-segment/add-edit-market-segment.component';
import { AddEditTierCategoryComponent } from './dashboard/configuration/add-edit-tier-category/add-edit-tier-category.component';
import { AddEditServiceCancellationComponent } from './dashboard/configuration/add-edit-service-cancellation/add-edit-service-cancellation.component';
import { SuperAdminDashboardComponent } from './super-admin-dashboard/super-admin-dashboard.component';
import { HotelsListComponent } from './super-admin-dashboard/hotel-management/hotels-list/hotels-list.component';
import { HotelChainsListComponent } from './super-admin-dashboard/hotel-management/hotel-chains-list/hotel-chains-list.component';
import { HotelSubchainsListComponent } from './super-admin-dashboard/hotel-management/hotel-subchains-list/hotel-subchains-list.component';
import { AddHotelComponent } from './super-admin-dashboard/hotel-management/hotels-list/add-hotel/add-hotel.component';
import { AddHotelChainComponent } from './super-admin-dashboard/hotel-management/hotel-chains-list/add-hotel-chain/add-hotel-chain.component';
import { AddHotelSubchainComponent } from './super-admin-dashboard/hotel-management/hotel-subchains-list/add-hotel-subchain/add-hotel-subchain.component';

@NgModule({
  declarations: [
    HomeComponent,
    DashboardComponent,
    OffersComponent,
    FaqsComponent,
    AddComponent,
    AddFaqComponent,
    SpaBannersComponent,
    SpaCategoriesComponent,
    SpaComponent,
    SpaTypesComponent,
    UsersListComponent,
    AddUserComponent,
    PrivacyPolicyComponent,
    DialogDeletePolicyComponent,
    SpaCalendarComponent,
    RoomDiningComponent,
    RoomDiningBannersComponent,
    RoomDiningCategoriesComponent,
    RoomDiningTypesComponent,
    PackageCalendarComponent,
    TransportCalendarComponent,
    ConferenceCalendarComponent,
    TransportComponent,
    TransportBannersComponent,
    TransportCategoriesComponent,
    TransportTypesComponent,
    HousekeepingComponent,
    HousekeepingBannersComponent,
    ConfigurationComponent,
    LogoutConfirmationComponent,
    ConferenceRoomComponent,
    ConferenceRoomBannersComponent,
    ConferenceRoomCategoriesComponent,
    ConferenceRoomTypesComponent,
    PackageComponent,
    PackageBannersComponent,
    PackageCategoriesComponent,
    PackageTypesComponent,
    HousekeepingServicesComponent,
    AdminsListComponent,
    AddAdminComponent,
    ServicesListComponent,
    AddEditAreaComponent,
    AddEditRoomComponent,
    AddEditShiftComponent,
    AddEditRoomAllocationComponent,
    UploadedUsersComponent,
    UploadUsersSheetComponent,
    EditServiceTimingComponent,
    RoomsComponent,
    AboutComponent,
    RoomBannersComponent,
    RoomCategoriesComponent,
    AboutBannersComponent,
    AboutCategoriesComponent,
    AboutTypesComponent,
    AddEditDiningfilterComponent,
    RatingServiceComponent,
    AddRatingComponent,
    ConfirmationPopupComponent,
    ToolbarComponent,
    BookingNotificationsComponent,
    AddAppUsersComponent,
    AppQRcodeComponent,
    ProfileComponent,
    RatingFilterComponent,
    WebsiteManagementComponent,
    AddEditTaxComponent,
    GuestRequestsComponent,
    InternalRequestsComponent,
    SlaDashboardComponent,
    SlaManagementComponent,
    BookServiceComponent,
    SlotCheckPopupComponent,
    RequestConfirmedPopupComponent,
    BookDiningComponent,
    UpdateGuestBookingComponent,
    UpdateInternalBookingComponent,
    CsatGraphComponent,
    CustomerSupportComponent,
    SlaGraphComponent,
    SuccessRateGraphComponent,
    FileDragDropDirective,
    RoomTypeComponent,
    RatePlanComponent,
    ViewRatePlansComponent,
    BulkInventoryComponent,
    BulkRateComponent,
    AddEditCancellationComponent,
    DashboardGraphComponent,
    DashboardSlaComponent,
    RatingComponent,
    RevenueComponent,
    InventoryReservationsComponent,
    AddReservationComponent,
    EditReservationComponent,
    ArrivalsComponent,
    DeparturesComponent,
    CheckInComponent,
    CheckOutComponent,
    CheckInCardComponent,
    CheckOutCardComponent,
    CheckoutPaymentDetailsComponent,
    RoomInventoryComponent,
    AddEditMarketSegmentComponent,
    AddEditTierCategoryComponent,
    AddEditServiceCancellationComponent,
    SuperAdminDashboardComponent,
    HotelsListComponent,
    HotelChainsListComponent,
    HotelSubchainsListComponent,
    AddHotelComponent,
    AddHotelChainComponent,
    AddHotelSubchainComponent
  ],
  imports: [
    HomeRoutingModule,
    CommonModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    MatDialogModule,
    QuillModule.forRoot(),
    MatBadgeModule,
    QRCodeModule,
    NgxInfiniteScrollerModule
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    BookingNotificationsComponent
  ],
  entryComponents: [
    SpaBannersComponent,
    SpaCategoriesComponent,
    SpaTypesComponent,
    RoomDiningBannersComponent,
    RoomDiningCategoriesComponent,
    RoomDiningTypesComponent,
    AddHotelChainComponent,
    AddHotelSubchainComponent,
    AddHotelComponent,
    AddAdminComponent,
    AddUserComponent,
    DialogDeletePolicyComponent,
    SpaCalendarComponent,
    PackageCalendarComponent,
    TransportCalendarComponent,
    ConferenceCalendarComponent,
    TransportBannersComponent,
    TransportCategoriesComponent,
    TransportTypesComponent,
    HousekeepingBannersComponent,
    HousekeepingServicesComponent,
    LogoutConfirmationComponent,
    ConferenceRoomBannersComponent,
    ConferenceRoomCategoriesComponent,
    ConferenceRoomTypesComponent,
    PackageBannersComponent,
    PackageCategoriesComponent,
    PackageTypesComponent,
    AddEditAreaComponent,
    AddEditRoomComponent,
    AddEditRoomAllocationComponent,
    UploadUsersSheetComponent,
    EditServiceTimingComponent,
    RoomBannersComponent,
    RoomCategoriesComponent,
    AboutBannersComponent,
    AboutCategoriesComponent,
    AboutTypesComponent,
    AddEditDiningfilterComponent,
    AddRatingComponent,
    ConfirmationPopupComponent,
    BookingNotificationsComponent,
    AddAppUsersComponent,
    AppQRcodeComponent,
    RatingFilterComponent,
    AddEditTaxComponent,
    BookServiceComponent,
    SlotCheckPopupComponent,
    RequestConfirmedPopupComponent,
    BookDiningComponent,
    UpdateGuestBookingComponent,
    UpdateInternalBookingComponent,
    ViewRatePlansComponent,
    AddEditCancellationComponent,
    CheckInCardComponent,
    CheckOutCardComponent
  ],
})
export class HomeModule { }