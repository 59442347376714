<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
                    <div class="week-text" (click)="thisWeekdata()">This Week</div>
                    <div class="vertical-line"></div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                       
                            <mat-form-field [hideUnderline]="true" class="text-box-date" >
                                <mat-date-range-input [formGroup]="range" [rangePicker]="picker" class="hidden-inputs">
                                    <input matStartDate formControlName="start" readonly />
                                    <input matEndDate formControlName="end" readonly />
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                   
                    </div>
                </div>
                <!-- <div fxLayout="row" fxLayoutGap="25px">
                    <button class="add-btn" (click)="saveInventory()" [disabled]="accessType == 2">
                        New Reservation
                    </button>
                </div> -->
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="90%" fxLayout="row">
                      <!-- <div fxFlex="10%" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                        <div class="option-text">Ratings</div>
                        <mat-select class="rating-box" appearance="fill" [formControl]="roomType">
                            <mat-option *ngFor=" let rating of ratingList" [value]="rating">{{rating}}</mat-option>
                        </mat-select>
                    </div> -->
                    <!-- <div class="vertical-line" style="margin-right: 20px;"></div> -->
                  <div fxFlex="50%" fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
                    <div class="option-text">Room Category</div>
                    <mat-select class="text-box" appearance="fill" [formControl]="roomType" placeholder="Select">
                      <!-- <mat-option [value]="null">All Categories</mat-option> -->
                      <mat-option *ngFor="let room of roomTypes" [value]="room.ID">
                        {{ room.NAME }}
                      </mat-option>
                    </mat-select>
                  </div>
        
        
                  <div class="vertical-line" style="margin-right: 30px;"></div>
        
                  <!-- Room Type Dropdown -->
                  <div fxFlex="50%" fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
                    <div class="option-text">Room Type</div>
                    <mat-select class="text-box" appearance="fill" [formControl]="roomClass" placeholder="Select">
                      <mat-option *ngFor="let roomClass of roomClasses" [value]="roomClass.ID">
                        {{ roomClass.NAME }}
                      </mat-option>
                    </mat-select>
                  </div>
                  <!-- <div class="week-text" fxFlex="10%" style="margin-top: 10px;text-decoration: underline;"
                        (click)="thisWeekdata()">Clear All</div> -->
                </div>
              </div>
            
            <mat-divider></mat-divider>
            <div class="reserve-plan">
                <div class="row header-row">
                    <div class="cell room-header"></div>
                    <div *ngFor="let day of selectedDays; let i = index" class="cell date-header" [ngClass]="{
                                'highlight-day': isToday(day),
                                'selected-start': isSameDay(day, selectedStartDate),
                                'selected-end': isSameDay(day, selectedEndDate),
                                'in-range': isInRange(day, selectedStartDate, selectedEndDate)}">
                      <div class="date-number">{{ day | date: 'dd' }}</div>
                      <div class="day-name">{{ day | date: 'EEE' }}</div>
                    </div>
                  </div>
            
                <!-- Room Type and Room Numbers -->
                <div *ngFor="let roomClass of filteredRoomClasses" class="room-type-section">
                    <div class="row room-type-row">
                        <div class="cell room-type-header" [attr.colspan]="selectedDays.length">
                            {{ roomClass.NAME }}
                        </div>
                    </div>
            
                    <!-- Handle case where no rooms are available -->
                    <div *ngIf="roomClass.ROOMS && roomClass.ROOMS.length === 0" class="row no-rooms-row">
                        <div class="cell" style="background-color: rgb(255, 255, 255); font-family: Arial;" [attr.colspan]="selectedDays.length">
                            No rooms associated
                        </div>
                    </div>
            
                    <!-- Rooms available for each room class -->
                    <div *ngIf="roomClass?.ROOMS && roomClass?.ROOMS.length !== 0">
                        <div class="row room-row">
                          <div class="cell room-number">Availability</div>
                          <div *ngFor="let day of selectedDays" class="cell room-data">
                            <ng-container *ngIf="getAvailability(roomClass?.NAME, roomClass?.ID, day) !== 0; else cancel">
                              <img src="../../../../assets/images\Group 8814.png">
                            </ng-container>
                            <ng-template #cancel>
                              <img src="../../../../assets/images\ic_baseline-cancel.png">
                            </ng-template>
                          </div>
                        </div>
                      
                        <div class="row room-row">
                          <div class="cell room-number">Inventory</div>
                          <div *ngFor="let day of selectedDays" class="cell room-data">
                            {{ getInventory(roomClass?.NAME, roomClass?.ID, day) }}
                          </div>
                        </div>
                        
                        <div class="row room-row">
                          <div class="cell room-number">Booked</div>
                          <div *ngFor="let day of selectedDays" class="cell room-data">
                            {{ getBooked(roomClass?.NAME, roomClass?.ID, day) }}
                          </div>
                        </div>
                        
                        <div class="row room-row">
                          <div class="cell room-number">Remaining</div>
                          <div *ngFor="let day of selectedDays" class="cell room-data">
                            {{ getRemaining(roomClass?.NAME, roomClass?.ID, day) }}
                          </div>
                        </div>
                      </div>
                      
                </div>
            </div>
            
        </div>
    </div>
</div>