import { Component, HostListener, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from 'src/app/home/dashboard/logout-confirmation/logout-confirmation.component';
import { AddHotelSubchainComponent } from './add-hotel-subchain/add-hotel-subchain.component';
import { ConfirmationPopupComponent } from 'src/app/home/dashboard/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-hotel-subchains-list',
  templateUrl: './hotel-subchains-list.component.html',
  styleUrls: ['./hotel-subchains-list.component.scss']
})
export class HotelSubchainsListComponent {

  userInfo: any;
  userName: any;
  displayedColumns: string[] = ['chain', 'name', 'owner', 'description', 'action'];
  limit = 10;
  pageSize = 5;
  pageIndex = 0;
  loading: boolean = true;
  pageNumber = 0;
  resArray: Array<any> = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('paginatorRef') paginatorRef: MatPaginator;
  private _userData = new BehaviorSubject<any[]>([]);
  private userDataStore: { $userData: any[] } = { $userData: [] };
  readonly $userData = this._userData.asObservable();
  currentPageIndex: any;
  previousPageIndex: any;
  visitedIndex: Array<any> = [0];
  lastPage: number;
  confirmationMsg: any = {};
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public adminService: AdminService,
    private notify: NotificationService
  ) { }

  ngOnInit(): void {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.getSubChains(this.pageNumber);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getSubChains(pageNo) {
    this.adminService.getHotelSubChains(pageNo, this.limit).subscribe({
      next: (data) => {
        this.loading = false;
        this.resArray = this.resArray.concat(data.object);
        this.userDataStore.$userData = this.resArray;
        this.dataSource.data = this.userDataStore.$userData;
        this.dataSource = new MatTableDataSource<any>(this.dataSource.data);
        this.dataSource.paginator = this.paginator.toArray()[0];
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  changePage(event) {
    if (this.visitedIndex.indexOf(event.pageIndex) == -1) {
      this.visitedIndex.push(event.pageIndex);
    }
    this.currentPageIndex = event.pageIndex;
    this.previousPageIndex = event.previousPageIndex
    if (this.currentPageIndex > this.previousPageIndex) {
      if (this.visitedIndex.indexOf(this.currentPageIndex + 1)) {
        this.pageNumber++;
        this.loading = true;
        this.getSubChains(this.pageNumber);
      }
    }
  }

  updateSubChainDetails(obj) {
    this.userDataStore.$userData.forEach((t: any, i) => {
      if (t.ID === obj.ID) {
        this.userDataStore.$userData[i] = obj;
      }
    });
    this._userData.next(Object.assign({}, this.userDataStore).$userData);
    this.$userData.subscribe((res) => {
      this.resArray = res;
      this.dataSource.data = res;
      this.dataSource = new MatTableDataSource<any>(res);
      this.dataSource.paginator = this.paginator.toArray()[0];
    })
  }

  addSubChain() {
    const dialogRef = this.dialog.open(AddHotelSubchainComponent, {
      width: '558px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.pageNumber = 0;
        this.resArray = [];
        this.dataSource.data = [];
        this.getSubChains(this.pageNumber);
      }
    });
  }

  editSubChain(id) {
    const dialogRef = this.dialog.open(AddHotelSubchainComponent, {
      width: '558px',
      height: 'auto',
      data: { 'ID': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateSubChainDetails(result);
    });
  }

  deleteSubChain(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this hotel sub-chain ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.adminService.updateHotelSubChain(obj).subscribe({
          next: (data) => {
            this.loading = false;
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.userDataStore.$userData.forEach((t: any, i) => {
              if (t.ID === id) {
                this.userDataStore.$userData.splice(i, 1);
              }
            });
            this._userData.next(Object.assign({}, this.userDataStore).$userData);
            this.$userData.subscribe((res) => {
              this.resArray = res;
              this.dataSource = new MatTableDataSource<any>(res);
              this.dataSource.paginator = this.paginator.toArray()[0];
              if (this.userDataStore.$userData.length % 5 == 0) {
                this.paginatorRef.previousPage();
              }
            })
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}