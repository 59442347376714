import { Component, HostListener, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { AddEditAreaComponent } from './add-edit-area/add-edit-area.component';
import { MatDialog } from '@angular/material/dialog';
import { AddEditRoomComponent } from './add-edit-room/add-edit-room.component';
import { AddEditShiftComponent } from './add-edit-shift/add-edit-shift.component';
import { AddEditRoomAllocationComponent } from './add-edit-room-allocation/add-edit-room-allocation.component';
import { FormControl } from '@angular/forms';
import { EditServiceTimingComponent } from './edit-service-timing/edit-service-timing.component';
import { Select } from '@ngxs/store';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Observable } from 'rxjs';
import { HotelState } from 'src/app/session/shared/store/state/hotel-info.state';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { Router } from '@angular/router';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { AddEditTaxComponent } from './add-edit-tax/add-edit-tax.component';
import { AddEditCancellationComponent } from './add-edit-cancellation/add-edit-cancellation.component';
import { AddEditMarketSegmentComponent } from './add-edit-market-segment/add-edit-market-segment.component';
import { AddEditTierCategoryComponent } from './add-edit-tier-category/add-edit-tier-category.component';
import { AddEditServiceCancellationComponent } from './add-edit-service-cancellation/add-edit-service-cancellation.component';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent {

  areaColumns: string[] = ['name', 'description', 'action'];
  roomColumns: string[] = ['name', 'area', 'description', 'action'];
  shiftColumns: string[] = ['name', 'description', 'from', 'to', 'action'];
  roomAllocationColumns: string[] = ['user', 'role', 'service', 'rooms', 'shift', 'action']
  serviceColumns: string[] = ['name', 'from', 'to', 'action'];
  taxColumns: string[] = ['name', 'percentage', 'service', 'action'];
  cancellationColumns: string[] = ['name', 'description', 'ratePlans', 'action'];

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatTable) table: MatTable<any>;
  loading: boolean;
  showArea: boolean = true;
  showRoom: boolean = false;
  showShift: boolean = false;
  showRoomAllocation: boolean = false;
  showServiceTimings: boolean = false;
  showTheme: boolean = false;
  showTax: boolean = false;
  showCancellation: boolean = false;
  showMarketSegment: boolean = false;
  showTierCategories: boolean = false;
  showServiceCancellation: boolean = false;
  background = new FormControl('');
  selection = new FormControl('');
  button = new FormControl('');
  searchVal = new FormControl('');
  filterByService = new FormControl('');
  isEnabled: boolean;
  themeInfo: any = [];
  currentBackgroundColor = '';
  currentSelectionColor = '';
  currentButtonColor = '';
  services = [];
  permittedServices = [];
  serviceTimings = [];
  permittedServiceTimings = [];
  color = '#262020';
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  @Select(HotelState.getClientInfo) hotelInfo$: Observable<[]>
  userInfo: any;
  userName: any;
  accessType: any;
  confirmationMsg: any = {};
  hotelServices: any = [];
  filterTaxByService: any = [];
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private cs: ConfigurationService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
      }
      this.hotelServices = window.localStorage.getItem('hotel_services');
      if (this.userInfo.roleId == 2) {
        for (let service of this.services) {
          if ((this.hotelServices.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true) && (service.OFFER_APPLICABLE == true)) {
            this.filterTaxByService.push(service);
          }
        }
      }
      else if (this.userInfo.roleId != 2) {
        for (let service of this.services) {
          if ((this.userInfo.serviceId.indexOf(service.ID) != -1) && (service.CONFIGURABLE == true) && (service.OFFER_APPLICABLE == true)) {
            this.filterTaxByService.push(service);
          }
        }
      }
    })
    this.getAreas();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  showAreas() {
    this.searchVal.setValue('');
    this.showArea = true;
    this.showRoom = false;
    this.showShift = false;
    this.showRoomAllocation = false;
    this.showServiceTimings = false;
    this.showTheme = false;
    this.showTax = false;
    this.showCancellation = false;
    this.showMarketSegment = false;
    this.showTierCategories = false;
    this.showServiceCancellation = false;
    this.dataSource.data = [];
    this.getAreas();
  }

  showRooms() {
    this.searchVal.setValue('');
    this.showArea = false;
    this.showRoom = true;
    this.showShift = false;
    this.showRoomAllocation = false;
    this.showServiceTimings = false;
    this.showTheme = false;
    this.showTax = false;
    this.showCancellation = false;
    this.showMarketSegment = false;
    this.showTierCategories = false;
    this.showServiceCancellation = false;
    this.dataSource.data = [];
    this.getRooms();
  }

  showShifts() {
    this.searchVal.setValue('');
    this.showArea = false;
    this.showRoom = false;
    this.showShift = true;
    this.showRoomAllocation = false;
    this.showServiceTimings = false;
    this.showTheme = false;
    this.showTax = false;
    this.showCancellation = false;
    this.showMarketSegment = false;
    this.showTierCategories = false;
    this.showServiceCancellation = false;
    this.dataSource.data = [];
    this.getShifts();
  }

  showRoomAllocations() {
    this.searchVal.setValue('');
    this.showArea = false;
    this.showRoom = false;
    this.showShift = false;
    this.showRoomAllocation = true;
    this.showServiceTimings = false;
    this.showTheme = false;
    this.showTax = false;
    this.showCancellation = false;
    this.showMarketSegment = false;
    this.showTierCategories = false;
    this.showServiceCancellation = false;
    this.dataSource.data = [];
    this.getRoomAllocations();
  }

  showServiceTiming() {
    this.searchVal.setValue('');
    this.showArea = false;
    this.showRoom = false;
    this.showShift = false;
    this.showRoomAllocation = false;
    this.showServiceTimings = true;
    this.showTheme = false;
    this.showTax = false;
    this.showCancellation = false;
    this.showMarketSegment = false;
    this.showTierCategories = false;
    this.showServiceCancellation = false;
    this.dataSource.data = [];
    this.loading = true;
    this.cs.getServiceTimings().subscribe({
      next: (res) => {
        this.serviceTimings = res.object;
        this.getServiceTiming();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  showThemeInfo() {
    this.searchVal.setValue('');
    this.showArea = false;
    this.showRoom = false;
    this.showShift = false;
    this.showRoomAllocation = false;
    this.showServiceTimings = false;
    this.showTheme = true;
    this.showTax = false;
    this.showCancellation = false;
    this.showMarketSegment = false;
    this.showTierCategories = false;
    this.showServiceCancellation = false;
    this.getTheme();
  }

  showTaxes() {
    this.searchVal.setValue('');
    this.filterByService.setValue('');
    this.showArea = false;
    this.showRoom = false;
    this.showShift = false;
    this.showRoomAllocation = false;
    this.showServiceTimings = false;
    this.showTheme = false;
    this.showTax = true;
    this.showCancellation = false;
    this.showMarketSegment = false;
    this.showTierCategories = false;
    this.showServiceCancellation = false;
    this.dataSource.data = [];
    this.getTaxes();
  }

  showCancellationPolicy() {
    this.searchVal.setValue('');
    this.showArea = false;
    this.showRoom = false;
    this.showShift = false;
    this.showRoomAllocation = false;
    this.showServiceTimings = false;
    this.showTheme = false;
    this.showTax = false;
    this.showCancellation = true;
    this.showMarketSegment = false;
    this.showTierCategories = false;
    this.showServiceCancellation = false;
    this.dataSource.data = [];
    this.getCancellationPolicyValue();
  }

  showMarketSegments() {
    this.searchVal.setValue('');
    this.showArea = false;
    this.showRoom = false;
    this.showShift = false;
    this.showRoomAllocation = false;
    this.showServiceTimings = false;
    this.showTheme = false;
    this.showTax = false;
    this.showCancellation = false;
    this.showMarketSegment = true;
    this.showTierCategories = false;
    this.showServiceCancellation = false;
    this.dataSource.data = [];
    this.getMarketSegment();
  }

  showTierCategory() {
    this.searchVal.setValue('');
    this.showArea = false;
    this.showRoom = false;
    this.showShift = false;
    this.showRoomAllocation = false;
    this.showServiceTimings = false;
    this.showTheme = false;
    this.showTax = false;
    this.showCancellation = false;
    this.showMarketSegment = false;
    this.showTierCategories = true;
    this.showServiceCancellation = false;
    this.dataSource.data = [];
    this.getTierCategory();
  }

  showServiceCancellationPolicy() {
    this.searchVal.setValue('');
    this.showArea = false;
    this.showRoom = false;
    this.showShift = false;
    this.showRoomAllocation = false;
    this.showServiceTimings = false;
    this.showTheme = false;
    this.showTax = false;
    this.showCancellation = false;
    this.showMarketSegment = false;
    this.showTierCategories = false;
    this.showServiceCancellation = true;
    this.dataSource.data = [];
    // this.getCancellationPolicyValue();
    this.getServiceCancellationPolicy();
  }

  searchShift(key) {
    this.loading = true;
    this.cs.getShiftBySearchKey(this.searchVal.value).subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getShifts() {
    this.loading = true;
    this.cs.getShifts().subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getAreas() {
    this.loading = true;
    this.cs.getAreas().subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  searchArea(key) {
    this.loading = true;
    this.cs.getAreaBySearchKey(this.searchVal.value).subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getRooms() {
    this.loading = true;
    this.cs.getRooms().subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  refresh() {
    this.searchVal.setValue('');
    if (this.showArea) {
      this.getAreas();
    } else if (this.showRoom) {
      this.getRooms();
    } else if (this.showShift) {
      this.getShifts();
    } else if (this.showTax) {
      this.filterByService.setValue('');
      this.getTaxes();
    } else if (this.showMarketSegment) {
      this.getMarketSegment();
    } else if (this.showTierCategories) {
      this.getTierCategory();
    }
  }

  searchRoom(key) {
    this.loading = true;
    this.cs.getRoomBySearchKey(this.searchVal.value).subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getRoomAllocations() {
    this.loading = true;
    this.cs.getRoomAllocations().subscribe({
      next: (res) => {
        this.dataSource.data = res.object.ALLOCATION;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  deleteArea(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this area ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.cs.updateArea(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.getAreas();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  addArea() {
    const dialogRef = this.dialog.open(AddEditAreaComponent, {
      width: '558px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getAreas();
      }
    });
  }

  editArea(id) {
    const dialogRef = this.dialog.open(AddEditAreaComponent, {
      width: '558px',
      height: 'auto',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getAreas();
      }
    });
  }

  addRoom() {
    const dialogRef = this.dialog.open(AddEditRoomComponent, {
      width: '558px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getRooms();
      }
    });
  }

  editRoom(id) {
    const dialogRef = this.dialog.open(AddEditRoomComponent, {
      width: '558px',
      height: 'auto',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getRooms();
      }
    });
  }

  deleteRoom(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this room ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.cs.updateRoom(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.getRooms();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  deleteShift(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this shift ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.cs.updateShift(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.getShifts();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  addShift() {
    const dialogRef = this.dialog.open(AddEditShiftComponent, {
      width: '558px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getShifts();
      }
    });
  }

  editShift(id) {
    const dialogRef = this.dialog.open(AddEditShiftComponent, {
      width: '558px',
      height: 'auto',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getShifts();
      }
    });
  }

  addAllocation() {
    const dialogRef = this.dialog.open(AddEditRoomAllocationComponent, {
      width: '558px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getRoomAllocations();
      }
    });
  }

  editAllocation(id) {
    const dialogRef = this.dialog.open(AddEditRoomAllocationComponent, {
      width: '558px',
      height: 'auto',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getRoomAllocations();
      }
    });
  }

  deleteAllocation(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this allocation ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "MEMBER_ID": id,
          "DELETED": true
        }
        this.cs.updateRoomAllocation(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.getRoomAllocations();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  getServiceTiming() {
    this.permittedServices = [];
    this.permittedServiceTimings = [];
    for (let value of window.localStorage.getItem('hotel_services')) {
      for (let service of this.services) {
        if (value == service.ID) {
          this.permittedServices.push(
            {
              ID: service.ID,
              NAME: service.NAME
            });
        }
      }
    }
    if (this.serviceTimings.length != 0) {
      for (let value of this.permittedServices) {
        for (let service of this.serviceTimings) {
          if (value.ID == service.SERVICE.ID && service.ENABLED == true) {
            this.permittedServiceTimings.push(
              {
                ID: service.SERVICE.ID,
                NAME: value.NAME,
                FROM: {
                  ID: service.FROM.ID,
                  TIME: service.FROM.TIME
                },
                TO: {
                  ID: service.TO.ID,
                  TIME: service.TO.TIME
                },
                ENABLED: service.ENABLED
              });
          }
          else if (value.ID == service.SERVICE.ID && service.ENABLED == false) {
            this.permittedServiceTimings.push(
              {
                ID: service.SERVICE.ID,
                NAME: value.NAME,
                FROM: {
                  ID: service.FROM.ID,
                  TIME: "00:00"
                },
                TO: {
                  ID: service.TO.ID,
                  TIME: "23:59"
                },
                ENABLED: service.ENABLED
              });
          }
        }
        const index = this.serviceTimings.findIndex(x => x.SERVICE.ID === value.ID);
        if (index == -1) {
          this.permittedServiceTimings.push(
            {
              ID: value.ID,
              NAME: value.NAME,
              FROM: {
                ID: 0,
                TIME: "__ : __"
              },
              TO: {
                ID: 0,
                TIME: "__ : __"
              },
              ENABLED: false
            });
        }
      }
    }
    else if (this.serviceTimings.length == 0) {
      this.permittedServices.forEach(element => {
        this.permittedServiceTimings.push(
          {
            ID: element.ID,
            NAME: element.NAME,
            FROM: {
              ID: 0,
              TIME: "__ : __"
            },
            TO: {
              ID: 0,
              TIME: "__ : __"
            },
            ENABLED: false
          });
      });
    }
    this.loading = false;
    this.dataSource.data = this.permittedServiceTimings;
  }

  editServiceTiming(s_id, name) {
    const dialogRef = this.dialog.open(EditServiceTimingComponent, {
      width: '558px',
      height: 'auto',
      data: { 'S_ID': s_id, 'NAME': name },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.showServiceTiming();
      }
    });
  }

  enableServiceTiming(isChecked, id, from, to) {
    this.loading = true;
    if (from == 0 || to == 0) {
      this.loading = false;
      this.showServiceTiming();
      this.notify.showMessage(
        "Please configure the From and To timings",
        "top",
        "warning"
      )
    }
    else {
      let obj = {
        "SERVICE": [
          {
            "S_ID": id,
            "FROM": from,
            "TO": to,
            "ENABLED": isChecked
          }
        ]
      }
      this.cs.updateServiceTiming(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.showServiceTiming();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  getTheme() {
    this.loading = true;
    this.cs.getThemes().subscribe({
      next: (res) => {
        this.themeInfo = res.object;
        this.background.setValue(res.object.BACKGROUND);
        this.selection.setValue(res.object.SELECTION);
        this.button.setValue(res.object.BUTTON);
        this.isEnabled = res.object.ENABLED;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  saveTheme() {
    this.loading = true;
    let obj = {
      "BACKGROUND": this.background.value,
      "SELECTION": this.selection.value,
      "BUTTON": this.button.value,
      "ENABLED": this.isEnabled
    }
    this.cs.updateThemes(obj).subscribe({
      next: (res) => {
        this.getTheme();
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectBackgroundColor(event, data) {
    this.currentBackgroundColor = data;
  }

  selectSelectionColor(event, data) {
    this.currentSelectionColor = data;
  }

  selectButtonColor(event, data) {
    this.currentButtonColor = data;
  }

  enableTheme(isChecked) {
    this.isEnabled = isChecked;
    this.saveTheme();
  }

  cancel() {
    this.background.setValue(this.color);
    this.selection.setValue(this.color);
    this.button.setValue(this.color);
    this.isEnabled = this.themeInfo.ENABLED;
  }

  getTaxes() {
    this.loading = true;
    this.cs.getTax().subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  searchTax() {
    this.loading = true;
    this.cs.getTaxBySearchKey(this.searchVal.value, this.filterByService.value).subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addTax() {
    const dialogRef = this.dialog.open(AddEditTaxComponent, {
      width: '558px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getTaxes();
      }
    });
  }

  editTax(id) {
    const dialogRef = this.dialog.open(AddEditTaxComponent, {
      width: '558px',
      height: 'auto',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getTaxes();
      }
    });
  }

  deleteTax(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this tax ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.cs.updateTax(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.getTaxes();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  getCancellationPolicyValue() {
    this.loading = true;
    this.cs.getCancellationPolicy().subscribe({
      next: (res) => {
        if (res?.object) {
          // Check if CANCELLATION_POLICY exists and is an array
          if (res.object.CANCELLATION_POLICY && Array.isArray(res.object.CANCELLATION_POLICY)) {
            // Assign the array to dataSource
            this.dataSource.data = res.object.CANCELLATION_POLICY.map(policy => {
              return {
                ...policy,
                roomRatePlanNames: policy.ROOM_RATE_PLAN?.map((plan: any) => plan.NAME || 'No Name') || [], // Use optional chaining
                cancellationWindowName: policy.CANCELLATION_WINDOW?.NAME || 'No Window', // Optional chaining
                cancellationWindowConditions: policy.CANCELLATION_WINDOW_CONDITION?.map(cond => {
                  return {
                    feesName: cond.CANCELLATION_FEES?.NAME || 'No Fee',
                    windowTime: cond.CANCELLATION_WINDOW || 'No Window Time'
                  };
                }) || [] // Ensure this is always an array
              };
            });
          } else {
            // Handle the case when CANCELLATION_POLICY is not an array
            this.dataSource.data = [{
              ...res.object,
              roomRatePlanNames: res.object.ROOM_RATE_PLAN?.map((plan: any) => plan.NAME || 'No Name') || [],
              cancellationWindowName: res.object.CANCELLATION_WINDOW?.NAME || 'No Window',
              cancellationWindowConditions: res.object.CANCELLATION_WINDOW_CONDITION?.map(cond => {
                return {
                  feesName: cond.CANCELLATION_FEES?.NAME || 'No Fee',
                  windowTime: cond.CANCELLATION_WINDOW || 'No Window Time'
                };
              }) || []
            }];
          }
        } else {
          this.dataSource.data = []; // Or however you want to handle it
        }
        this.loading = false; // Always set loading to false
      },
      error: (e) => {
        this.loading = false; // Ensure loading is set to false on error
        this.notify.showNotification(
          e.error?.message || 'An error occurred', // Fallback message
          "top",
          (!!colorObj[e.error?.status] ? colorObj[e.error.status] : "error"),
          e.error?.status || 'error' // Fallback for status
        );
      }
    });
  }

  editCancellation(id) {
    const dialogRef = this.dialog.open(AddEditCancellationComponent, {
      width: '880px',
      height: '560px',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getCancellationPolicyValue();
      }
    });
  }

  addCancellation() {
    const dialogRef = this.dialog.open(AddEditCancellationComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getRooms();
      }
    });
  }

  deleteCancellation(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this cancellation policy ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.cs.updateCancellationPolicy(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.getCancellationPolicyValue();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

  getMarketSegment() {
    this.loading = true;
    this.cs.getMarketSegment().subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  searchMarketSegment(key) {
    this.loading = true;
    this.cs.getMarketSegmentBySearchKey(this.searchVal.value).subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  deleteMarketSegment(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this market segment ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.cs.updateMarketSegment(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.getMarketSegment();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  addMarketSegment() {
    const dialogRef = this.dialog.open(AddEditMarketSegmentComponent, {
      width: '558px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getMarketSegment();
      }
    });
  }

  editMarketSegment(id) {
    const dialogRef = this.dialog.open(AddEditMarketSegmentComponent, {
      width: '558px',
      height: 'auto',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getMarketSegment();
      }
    });
  }

  getTierCategory() {
    this.loading = true;
    this.cs.getTierCategory().subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  searchTierCategory(key) {
    this.loading = true;
    this.cs.getTierCategoryBySearchKey(this.searchVal.value).subscribe({
      next: (res) => {
        this.dataSource.data = res.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  deleteTierCategory(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this Tier Category ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.cs.updateTierCategory(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.getTierCategory();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  addTierCategory() {
    const dialogRef = this.dialog.open(AddEditTierCategoryComponent, {
      width: '558px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getTierCategory();
      }
    });
  }

  editTierCategory(id) {
    const dialogRef = this.dialog.open(AddEditTierCategoryComponent, {
      width: '558px',
      height: 'auto',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getTierCategory();
      }
    });
  }

  getServiceCancellationPolicy() {
    this.loading = true;
    this.cs.getServiceCancellationPolicy().subscribe({
      next: (res) => {
        if (res?.object) {
          if (res.object.CANCELLATION_POLICY && Array.isArray(res.object.CANCELLATION_POLICY)) {
            this.dataSource.data = res.object.CANCELLATION_POLICY.map(policy => {
              return {
                ...policy,
                cancellationWindowName: policy.CANCELLATION_WINDOW?.NAME || 'No Window',
                cancellationWindowConditions: policy.CANCELLATION_WINDOW_CONDITION?.map(cond => {
                  return {
                    feesName: cond.CANCELLATION_FEES?.NAME || 'No Fee',
                    windowTime: cond.CANCELLATION_WINDOW || 'No Window Time'
                  };
                }) || []
              };
            });
          } else {
            this.dataSource.data = [{
              ...res.object,
              cancellationWindowName: res.object.CANCELLATION_WINDOW?.NAME || 'No Window',
              cancellationWindowConditions: res.object.CANCELLATION_WINDOW_CONDITION?.map(cond => {
                return {
                  feesName: cond.CANCELLATION_FEES?.NAME || 'No Fee',
                  windowTime: cond.CANCELLATION_WINDOW || 'No Window Time'
                };
              }) || []
            }];
          }
        } else {
          this.dataSource.data = [];
        }
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error?.message || 'An error occurred',
          "top",
          (!!colorObj[e.error?.status] ? colorObj[e.error.status] : "error"),
          e.error?.status || 'error'
        );
      }
    });
  }

  editServiceCancellation(id) {
    const dialogRef = this.dialog.open(AddEditServiceCancellationComponent, {
      width: '880px',
      height: '560px',
      data: { 'id': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getServiceCancellationPolicy();
      }
    });
  }

  addServiceCancellation() {
    const dialogRef = this.dialog.open(AddEditServiceCancellationComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.getServiceCancellationPolicy();
      }
    });
  }

  deleteServiceCancellation(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this cancellation policy ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.loading = true;
        let obj = {
          "ID": id,
          "DELETED": true
        }
        this.cs.updateServiceCancellationPolicy(obj).subscribe({
          next: (data) => {
            this.notify.showNotification(
              data.message,
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = false;
            this.getServiceCancellationPolicy();
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }


}