import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-room-inventory',
  templateUrl: './room-inventory.component.html',
  styleUrls: ['./room-inventory.component.scss']
})

export class RoomInventoryComponent {
  range: FormGroup;
  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  mobile: boolean;
  roomTypes: any[] = []; // Stores room categories
  roomClasses: any[] = []; // Stores room types
  roomType = new FormControl('');
  roomClass = new FormControl('');
  roomCategories: any[] = [];
  selectedCategoryDetails: any = null;
  filteredRoomClasses: any[] = [];
  week: Date[] = [];
  selectedStartDate: any;
  selectedEndDate: any;
  selectedDays: any[] = [];
  ratings = new FormControl('');
  isExpanded = false;
  ratingList: string[] = ['1', '2', '3', '4', '5'];
  roomNumbers = [];
  roomStatusMap: any = {}; 
  data: any = {}; // Store API response

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private hotelService: HotelServicesService
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    // Initialize date range
    const curr = new Date();
    const firstDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1)); // Monday of the current week
    const endOfWeek = new Date(firstDayOfWeek);
    endOfWeek.setDate(firstDayOfWeek.getDate() + 13);

    // Set initial values for the date range picker
    this.range = new FormGroup({
      start: new FormControl(firstDayOfWeek),
      end: new FormControl(endOfWeek)
    });

    this.selectedStartDate = firstDayOfWeek;
    this.selectedEndDate = endOfWeek;
    this.selectedDays = this.getSelectedRangeDays();
    // Subscribe to range changes
    this.range.get('start')?.valueChanges.pipe(
      debounceTime(300), // Wait for 300ms after the last input before triggering the event
      distinctUntilChanged() // Ensure the value actually changes before triggering
    ).subscribe(startDate => {
      if (startDate) {
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 13);
        this.range.get('end')?.setValue(endDate, { emitEvent: false }); // Emit false to prevent recursion
        this.selectedStartDate = startDate;
        this.selectedEndDate = endDate;
        this.getAssociatedStatus();
      }
    });
    this.getAssociatedStatus();
    this.fetchRoomCategories();

    this.roomType.valueChanges.subscribe((selectedCategoryId) => {
      if (selectedCategoryId) {
        this.fetchRoomTypeByCategory(+selectedCategoryId);
      } else {
        this.roomClasses = [];
        this.filteredRoomClasses = [];
      }
    });

    this.roomClass.valueChanges.subscribe((selectedRoomClassId) => {
      this.filterRoomsByType(selectedRoomClassId);
    });
    
    // Object.keys(this.roomNumbers || {}).forEach((roomClass) => {
    //   this.roomNumbers[roomClass].forEach((roomNumber) => {
    //     this.week.forEach((day) => {
    //       const key = this.generateKey(roomClass, roomNumber, day);
    //       this.roomStatusMap[key] = { status: '', user: '', reservationID: '', bookingID: '' };
    //     });
    //   });
    // });
  }
 
  thisWeekdata() {
    const curr = new Date();
    const firstDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    const endOfWeek = new Date(firstDayOfWeek);
    endOfWeek.setDate(firstDayOfWeek.getDate() + 13);
    this.range.get('start')?.setValue(firstDayOfWeek);
    this.range.get('end')?.setValue(endOfWeek);
    this.selectedStartDate = firstDayOfWeek;
    this.selectedEndDate = endOfWeek;
    this.selectedDays = this.getSelectedRangeDays();
    this.getAssociatedStatus();
  }
  
  getSelectedRangeDays() {
    const days = [];
    if (this.selectedStartDate && this.selectedEndDate) {
      let currentDate = new Date(this.selectedStartDate);
      while (currentDate <= this.selectedEndDate) {
        days.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    return days;
  }
  isSameDay(date1: Date, date2: Date): boolean {
    return date1 && date2 && date1.toDateString() === date2.toDateString();
  }

  isInRange(date: Date, startDate: Date, endDate: Date): boolean {
    return date >= startDate && date <= endDate;
  }

  formatDate(date: Date = new Date()): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  convertToDate(dateString) {
    const [day, month, year] = dateString.split('-');
    const date = new Date(`${year}-${month}-${day}T00:00:00Z`);
    return date;
  }
  getAssociatedStatus() {
    this.loading = true;
    this.loading = true;
    const curr = new Date();
    const firstDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
    const lastDayOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
    const fromDate = this.formatDate(this.selectedStartDate);
    const toDate = this.formatDate(this.selectedEndDate);
    this.selectedDays = this.getSelectedRangeDays();
    this.hotelService.getInventoryData(fromDate, toDate).subscribe({
      next: (response: any) => {
        if (response.status === 200 && response.object) {
          this.populateRoomStatusMap(response.object);
        }
      },
      error: (err) => {
        console.error('Error fetching inventory data:', err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  populateRoomStatusMap(data: any) {
    this.roomStatusMap = {}; // Reset the map
  
    Object.keys(data).forEach((key) => {
      const roomData = data[key];
  
      if (roomData.ID && roomData.NAME) {
        Object.keys(roomData.FILTERED_DATA).forEach((date) => {
          const keyGenerated = this.generateKey(roomData.NAME, roomData.ID, date);
          // console.log(date , "checking the date")
          this.roomStatusMap[keyGenerated] = roomData.FILTERED_DATA[date];
          // console.log(`Room Status for ${keyGenerated}:`, this.roomStatusMap[keyGenerated]);
        });
      }
    });
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()
    );
  }

  generateKey(categoryname: number, roomId: number, date: string): string {
    let dateToString = date;
    if(typeof date !== "string"){
       dateToString = this.formatDate(new Date(date)) 
    }
    // console.log(dateToString, "checking the date")
    return `${categoryname}_${roomId}_${dateToString}`;
  }
  getAvailability(categoryname: number, roomId: number, date: string): number {
    const key = this.generateKey(categoryname, roomId, date);
    // console.log(key,"checking")
    // console.log(`Checking Availability for key: ${key}`);
    return this.roomStatusMap[key]?.AVAILABILITY || 0;
  }
  
  getInventory(categoryname: number, roomId: number, date: string): number {
    const key = this.generateKey(categoryname, roomId, date);
    // console.log(`Checking Inventory for key: ${key}`, this.roomStatusMap[key]?.INVENTORY)
    return this.roomStatusMap[key]?.INVENTORY || 0;
  }
  
  getBooked(categoryname: number, roomId: number, date: string): number {
    const key = this.generateKey(categoryname, roomId, date);
    // console.log(`Checking Booked for key: ${key}`);
    return this.roomStatusMap[key]?.BOOKED || 0;
  }
  
  getRemaining(categoryname: number, roomId: number, date: string): number {
    const key = this.generateKey(categoryname, roomId, date);
    // console.log(`Checking Remaining for key: ${key}`);
    return this.roomStatusMap[key]?.REMAINING || 0;
  }
  

  fetchRoomCategories(): void {
    this.hotelService.getRoomCategoryValue().subscribe({
      next: (response) => {
        if (response?.status === 200 && response.object?.length) {
          this.roomTypes = response.object;
          this.fetchAllRoomTypes(); // Fetch all room types initially
        }
      },
      error: (err) => {
        console.error('Error fetching room categories:', err);
      },
    });
  }

  fetchAllRoomTypes(): void {
    this.hotelService.getRoomCategoryValue().subscribe({
      next: (response) => {
        if (response?.status === 200 && response.object?.length) {
          this.roomClasses = response.object.flatMap((category) => category.CATEGORY_TYPE);
          this.filteredRoomClasses = this.roomClasses;
          // this.enrichRoomData();
        }
      },
      error: (err) => {
        console.error('Error fetching all room types:', err);
      },
    });
  }

  fetchRoomTypeByCategory(categoryname: number): void {
    this.hotelService.getRoomCategoryTypeValue(categoryname).subscribe({
      next: (response) => {
        if (response?.status === 200 && response.object?.length) {
          this.roomClasses = response.object[0].CATEGORY_TYPE;
          this.filteredRoomClasses = this.roomClasses;
          // this.enrichRoomData();
        } else {
          this.roomClasses = [];
          this.filteredRoomClasses = [];
        }
      },
      error: (err) => {
        console.error('Error fetching room types:', err);
      },
    });
  }
  filterRoomsByType(selectedRoomClassId: string): void {
    if (selectedRoomClassId) {
      this.filteredRoomClasses = this.roomClasses.filter((roomClass) => roomClass.ID === selectedRoomClassId);
    } else {
      this.filteredRoomClasses = this.roomClasses; // Show all room classes if no room class is selected
    }
  }
  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}