import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { environment } from 'src/environments/environment';
import { HousekeepingBannersComponent } from './housekeeping-banners/housekeeping-banners.component';
import { HousekeepingServicesComponent } from './housekeeping-services/housekeeping-services.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Router } from '@angular/router';
import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';

@Component({
  selector: 'app-housekeeping',
  templateUrl: './housekeeping.component.html',
  styleUrls: ['./housekeeping.component.scss']
})
export class HousekeepingComponent {

  housekeepingBanners = [];
  deleteBannerBody = {};
  housekeepingServices = [];
  deleteServiceBody = {};
  public bucketUrl = `${environment.BUCKET_URL}`;
  loading: boolean = true;
  serviceTiming = [];
  userInfo: any;
  userName: any;
  accessType: any;
  confirmationMsg: any = {};
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  @ViewChild('bannerContent', {}) public bannerContent;

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    public hotelService: HotelServicesService,
    private cs: ConfigurationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.getBanners();
    this.getServices();
    this.cs.getServiceTimings().subscribe({
      next: (services) => {
        this.serviceTiming = services.object;
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getBanners() {
    this.hotelService.getHousekeepingBanner().subscribe({
      next: (data) => {
        this.loading = false;
        this.housekeepingBanners = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addBanners() {
    const dialogRef = this.dialog.open(HousekeepingBannersComponent, {
      width: '558px',
      height: 'auto',
      data: { name: "add_banner" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getBanners();
      }
    });
  }

  updateBanner(id) {
    const dialogRef = this.dialog.open(HousekeepingBannersComponent, {
      width: '558px',
      height: 'auto',
      data: { ID: id, name: "update_banner" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getBanners();
      }
    });
  }

  deleteBanner(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this banner ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.deleteBannerBody = {
          "ID": id,
          "DELETED": true
        }
        this.hotelService.updateHousekeepingBanner(this.deleteBannerBody).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Document deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.getBanners();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  getServices() {
    this.hotelService.getHousekeepingServices().subscribe({
      next: (data) => {
        this.loading = false;
        this.housekeepingServices = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  addService() {
    const dialogRef = this.dialog.open(HousekeepingServicesComponent, {
      width: '558px',
      height: 'auto',
      data: { name: "add_service" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getServices();
      }
    });
  }

  updateService(id) {
    const dialogRef = this.dialog.open(HousekeepingServicesComponent, {
      width: '558px',
      height: 'auto',
      data: { ID: id, name: "update_service" },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loading = true;
        this.getServices();
      }
    });
  }

  deleteService(id) {
    this.confirmationMsg.title = 'Are you sure you want to delete this service ?';
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: { title: this.confirmationMsg.title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && dialogResult.state) {
        this.deleteServiceBody = {
          "ID": id,
          "DELETED": true
        }
        this.hotelService.updateHousekeepingService(this.deleteServiceBody).subscribe({
          next: (data) => {
            this.notify.showNotification(
              'Service deleted successfully',
              "top",
              (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
              data.status
            );
            this.loading = true;
            this.getServices();
          },
          error: (e) => {
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      }
    })
  }

  preview(source) {
    if (source.indexOf('https://') == -1 && source.indexOf('http://') == -1) {
      window.open(this.bucketUrl + source, '_blank');
    } else {
      window.open(source, '_blank');
    }
  }

  public bannerScrollRight(): void {
    this.bannerContent.nativeElement.scrollTo({ left: (this.bannerContent.nativeElement.scrollLeft + 500), behavior: 'smooth' });
  }

  public bannerScrollLeft(): void {
    this.bannerContent.nativeElement.scrollTo({ left: (this.bannerContent.nativeElement.scrollLeft - 500), behavior: 'smooth' });
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}