<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Update chain':'Create new chain'}}</span>
        </div>
        <div>
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="addClientForm">
        <div mat-dialog-content class="contents" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Name of the chain
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="chainName" placeholder="Enter chain name">
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['chainName'].touched && addClientForm.get('chainName').hasError('required')">
                            Please enter the hotel chain name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['chainName'].errors?.minlength || addClientForm.controls['chainName'].errors?.maxlength || addClientForm.controls['chainName'].errors?.pattern">
                            Hotel chain name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Founder
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="founder" placeholder="Enter founder name">
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['founder'].touched && addClientForm.get('founder').hasError('required')">
                            Please enter the founder name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['founder'].errors?.minlength || addClientForm.controls['founder'].errors?.maxlength || addClientForm.controls['founder'].errors?.pattern">
                            Founder name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Owner
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="owner" placeholder="Enter owner name">
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['owner'].touched && addClientForm.get('owner').hasError('required')">
                            Please enter the owner name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['owner'].errors?.minlength || addClientForm.controls['owner'].errors?.maxlength || addClientForm.controls['owner'].errors?.pattern">
                            Owner name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="100%">
                    <div class="box-name">
                        Description
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="description" placeholder="Describe"> </textarea>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['description'].touched && addClientForm.get('description').hasError('required')">
                            Please enter description (letters only, 2-300 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['description'].errors?.minlength || addClientForm.controls['description'].errors?.maxlength || addClientForm.controls['description'].errors?.pattern">
                            Description should contain only letters and be between 2 and 300 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl" class="actions">
                <button mat-stroked-button [ngClass]="this.addClientForm.invalid ? 'button' : 'save-button'"
                    [disabled]="this.addClientForm.invalid" (click)="addChain()">Save</button>
                <button mat-stroked-button class="button" style="margin-right: 18px;" (click)="cancel()">Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>