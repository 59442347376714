import { ChangeDetectorRef, Component, EventEmitter, HostListener, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { GetMasterData } from 'src/app/session/shared/store/actions/user-info.actions';
import { HotelState } from 'src/app/session/shared/store/state/hotel-info.state';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { LogoutConfirmationComponent } from '../dashboard/logout-confirmation/logout-confirmation.component';
import { environment } from 'src/environments/environment';
import { BookingNotificationsComponent } from '../booking-notifications/booking-notifications.component';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { AppQRcodeComponent } from '../super-admin-dashboard/hotel-management/hotels-list/app-qrcode/app-qrcode.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  @Output() outfilter: EventEmitter<any> = new EventEmitter<any>();
  @Select(HotelState.getClientInfo) hotelInfo$: Observable<[]>
  @Select(HotelState.getClientInfoLoaded) hotelInfoloaded$: Observable<boolean>
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>
  @Select(MasterDataState.isMasterDataLoaded) masterDataLoaded$: Observable<boolean>
  masterDataSubscription: Subscription
  hotelInfoSubscribtion: Subscription;
  roles: any;
  userRole: any;
  userInfo: any;
  roleId: any;
  clientID: any;
  hotelName: string;
  imageURL: string;
  public bucket_url = `${environment.BUCKET_URL}`;
  requestSelectedIndex;
  slaSelectedIndex;
  roomRateSelectedIndex;
  bulkUpdateSelectedIndex;
  reservationSelectedIndex;
  currentUrl: string;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private hotelService: HotelServicesService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url
        if (this.currentUrl == '/guest-request') {
          this.requestSelectedIndex = 0;
        }
        else if (this.currentUrl == '/internal-request') {
          this.requestSelectedIndex = 1;
        }
        else if (this.currentUrl == '/sla-dashboard') {
          this.slaSelectedIndex = 0;
        }
        else if (this.currentUrl == '/sla-management') {
          this.slaSelectedIndex = 1;
        }
        else if (this.currentUrl == '/rooms' || this.currentUrl == '/room-type') {
          this.roomRateSelectedIndex = 0;
        }
        else if (this.currentUrl == '/rate-plan') {
          this.roomRateSelectedIndex = 1;
        }
        else if (this.currentUrl == '/bulk-inventory-update') {
          this.bulkUpdateSelectedIndex = 0;
        }
        else if (this.currentUrl == '/bulk-rate-update') {
          this.bulkUpdateSelectedIndex = 1;
        }
        else if (this.currentUrl == '/all-reservations') {
          this.reservationSelectedIndex = 0;
        }
        else if (this.currentUrl == '/arrivals') {
          this.reservationSelectedIndex = 1;
        }
        else if (this.currentUrl == '/departures') {
          this.reservationSelectedIndex = 2;
        }
      }
    });
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.clientID = window.localStorage.getItem('tenant_id');
    this.hotelName = window.localStorage.getItem('hotel_name');
    this.imageURL = this.bucket_url + window.localStorage.getItem('hotel_icon');
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.roleId = this.userInfo.roleId;
    this.masterDataSubscription = this.masterDataLoaded$.subscribe(res => {
      if (!res) {
        this.store.dispatch(new GetMasterData());
      }
    })
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.roles = res['ROLE'];
        for (let role of this.roles) {
          if (role.ID == this.userInfo.roleId) {
            this.userRole = role.NAME;
          }
        }
      }
    })
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    this.checkScreenWidth();
    this.cdr.detectChanges();
  }

  roomRateChanged(event) {
    if (event.index == 0) {
      this.router.navigateByUrl('rooms');
      this.hotelService.changeAction('');
    } else {
      this.router.navigateByUrl('rate-plan');
    }
  }

  bulkUpdateChanged(event) {
    if (event.index == 0) {
      this.router.navigateByUrl('bulk-inventory-update');
    } else {
      this.router.navigateByUrl('bulk-rate-update');
    }
  }

  reservationsChanged(event) {
    if (event.index == 0) {
      this.router.navigateByUrl('all-reservations');
    } else if (event.index == 1) {
      this.router.navigateByUrl('arrivals');
    } else if (event.index == 2) {
      this.router.navigateByUrl('departures');
    }
  }

  requestTabChanged(event) {
    if (event.index == 0) {
      this.router.navigateByUrl('guest-request');
    } else {
      this.router.navigateByUrl('internal-request');
    }
  }

  slaTabChanged(event) {
    if (event.index == 0) {
      this.router.navigateByUrl('sla-dashboard');
    } else {
      this.router.navigateByUrl('sla-management');
    }
  }

  openQRCode(id) {
    const dialogRef = this.dialog.open(AppQRcodeComponent, {
      width: '400px',
      height: 'auto',
      data: { 'ID': id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openNotification() {
    const dialogRef = this.dialog.open(BookingNotificationsComponent, {
      width: '343px',
      height: '500px',
      position: { right: '145px' },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }

  viewProfile() {
    this.outfilter.emit({ 'action': 'viewProfile' });
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}