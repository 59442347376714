import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {

  constructor(private router: Router) { }

  private hotelManagement = new BehaviorSubject(false);
  currentHotelManagement = this.hotelManagement.asObservable();
  manageHotelManagement(hotelManagement) {
    this.hotelManagement.next(hotelManagement);
  }

  private service = new BehaviorSubject(false);
  currentService = this.service.asObservable();
  changeService(service) {
    this.service.next(service);
  }

  private operation = new BehaviorSubject(false);
  currentOperation = this.operation.asObservable();
  manageOperations(operation) {
    this.operation.next(operation);
  }

  private hotelOperation = new BehaviorSubject(false);
  currentHotelOperation = this.hotelOperation.asObservable();
  manageHotelOperations(hotelOperation) {
    this.hotelOperation.next(hotelOperation);
  }

  private reservation = new BehaviorSubject(false);
  currentReservation = this.reservation.asObservable();
  manageReservations(reservation) {
    this.reservation.next(reservation);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = window.localStorage.getItem('access_token');
    if (token) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    localStorage.clear();
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}