import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';

@Component({
  selector: 'app-add-hotel-chain',
  templateUrl: './add-hotel-chain.component.html',
  styleUrls: ['./add-hotel-chain.component.scss']
})
export class AddHotelChainComponent {

  addClientForm: FormGroup;
  loading: boolean = false;
  detailsPattern = /^[a-z A-Z]+$/;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddHotelChainComponent>,
    private fb: FormBuilder,
    private adminService: AdminService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.addClientForm = this.fb.group({
      chainName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      founder: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      owner: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern(this.detailsPattern)]],
      description: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(300), Validators.pattern(this.detailsPattern)]]
    });
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    if (!!this.data) {
      this.loading = true;
      this.adminService.getHotelChainById(this.data.ID).subscribe(res => {
        this.loading = false;
        this.addClientForm.get("chainName").setValue(res.object.NAME);
        this.addClientForm.get("founder").setValue(res.object.FOUNDER);
        this.addClientForm.get("owner").setValue(res.object.OWNER);
        this.addClientForm.get("description").setValue(res.object.DESCRIPTION);
      });
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  addChain() {
    this.loading = true;
    if (!!this.data) {
      let obj = {
        "ID": this.data.ID,
        "NAME": this.addClientForm.value.chainName,
        "DESCRIPTION": this.addClientForm.value.description,
        "FOUNDER": this.addClientForm.value.founder,
        "OWNER": this.addClientForm.value.owner
      }
      this.adminService.updateHotelChain(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
    else {
      let obj = {
        "NAME": this.addClientForm.value.chainName,
        "DESCRIPTION": this.addClientForm.value.description,
        "FOUNDER": this.addClientForm.value.founder,
        "OWNER": this.addClientForm.value.owner
      }
      this.adminService.createHotelChain(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

}