<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Update sub-chain':'Create new sub-chain'}}</span>
        </div>
        <div>
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="addClientForm">
        <div mat-dialog-content class="contents" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Select Chain
                    </div>
                    <div>
                        <mat-select class="text-box" style="font-size: 13px;" appearance="fill" formControlName="chainName"
                            placeholder="Select">
                            <mat-option *ngFor="let chain of hotelChains" [value]="chain.ID">{{chain.NAME}}</mat-option>
                        </mat-select>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['chainName'].touched && addClientForm.get('chainName').hasError('required')">
                            Please select hotel chain from the list.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Sub-Chain Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="subChainName" placeholder="Enter sub-chain name">
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['subChainName'].touched && addClientForm.get('subChainName').hasError('required')">
                            Please enter the hotel sub-chain name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['subChainName'].errors?.minlength || addClientForm.controls['subChainName'].errors?.maxlength || addClientForm.controls['subChainName'].errors?.pattern">
                            Hotel sub-chain name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        CEO
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="owner" placeholder="Enter CEO name">
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['owner'].touched && addClientForm.get('owner').hasError('required')">
                            Please enter the CEO name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['owner'].errors?.minlength || addClientForm.controls['owner'].errors?.maxlength || addClientForm.controls['owner'].errors?.pattern">
                            CEO name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="100%">
                    <div class="box-name">
                        Description
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="description" placeholder="Describe"> </textarea>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['description'].touched && addClientForm.get('description').hasError('required')">
                            Please enter description (letters only, 2-300 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['description'].errors?.minlength || addClientForm.controls['description'].errors?.maxlength || addClientForm.controls['description'].errors?.pattern">
                            Description should contain only letters and be between 2 and 300 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl" class="actions">
                <button mat-stroked-button [ngClass]="this.addClientForm.invalid ? 'button' : 'save-button'"
                    [disabled]="this.addClientForm.invalid" (click)="addSubChain()">Save</button>
                <button mat-stroked-button class="button" style="margin-right: 18px;" (click)="cancel()">Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>