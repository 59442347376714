import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { colorObj } from 'src/app/shared/color-object';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AdminService } from 'src/app/services/admin.service';
import { Select } from '@ngxs/store';
import { MasterDataState } from 'src/app/session/shared/store/state/master-data.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-edit-service-cancellation',
  templateUrl: './add-edit-service-cancellation.component.html',
  styleUrls: ['./add-edit-service-cancellation.component.scss']
})
export class AddEditServiceCancellationComponent {
  loading: boolean = false;
  cancellationForm: FormGroup;
  selectedButton: string = 'Hours';
  selectedRatePlans: any[] = [];
  roomRatePlan: any[] = [];
  @Select(MasterDataState.getMasterData) masterData$: Observable<[]>;
  cancellationFees = [];
  mobile: boolean;
  services: any[] = [];
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditServiceCancellationComponent>,
    private fb: FormBuilder,
    public hotelService: HotelServicesService,
    private cs: ConfigurationService,
    private notify: NotificationService,
    private admin: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.cancellationForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      cancellationWindow: ['0', Validators.required],
      cancellationWindowConditions: this.fb.array([]),
      percentage: [''],
      serviceName: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.cancellationForm.get('cancellationWindow').valueChanges.subscribe(value => {
      if (value === '1') {
        this.cancellationForm.get('cancellationWindowConditions').setValidators(Validators.required);
      } else {
        this.cancellationForm.get('cancellationWindowConditions').clearValidators();
      }
      this.cancellationForm.get('cancellationWindowConditions').updateValueAndValidity();
    });
    this.checkScreenWidth();
    this.masterData$.subscribe(res => {
      if (Object.keys(res).length != 0) {
        this.services = res['SERVICE'];
        this.cancellationFees = res['CANCELLATION_FEES'];
      }
    })
    if (this.data && this.data.id) {
      this.loading = true;
      this.cs.getServiceCancellationPolicyId(this.data?.id).subscribe({
        next: (res) => {
          this.loading = false;
          const policy = res.object;
          this.cancellationForm.get("name").setValue(policy.NAME);
          this.cancellationForm.get("description").setValue(policy.DESCRIPTION);
          if (policy.CANCELLATION_WINDOW.ID === 1) {
            this.cancellationForm.get('cancellationWindow').setValue('1');
            this.setCancellationWindowConditions(policy.CANCELLATION_WINDOW_CONDITION);
          } else {
            this.cancellationForm.get('cancellationWindow').setValue('2');
          }
          this.cancellationForm.get('serviceName').setValue(policy.SERVICE.ID);
          this.cancellationForm.get('serviceName').disable();

        },
        error: (e) => {
          this.loading = false;
        }
      });
    }

    this.addWindowCondition();

  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  get cancellationConditionFormArray() {
    return this.cancellationForm.get('cancellationWindowConditions') as FormArray;
  }

  setCancellationWindowConditions(conditions) {
    this.cancellationConditionFormArray.clear()
    const cancellationConditionArray = []
    conditions.forEach(condition => {
      const newCancellationCondition = {
        cutoffTime: condition.CANCELLATION_WINDOW,
        percentage: condition.CANCELLATION_FEES.ID,
        selectedButton: 'Hours'
      }
      cancellationConditionArray.push(newCancellationCondition);
    });
    cancellationConditionArray.forEach(item => {
      this.cancellationConditionFormArray.push(this.fb.group(item))
    })
  }

  get cancellationWindowConditions(): FormArray {
    return this.cancellationForm.get('cancellationWindowConditions') as FormArray;
  }

  addWindowCondition() {
    const windowGroup = this.fb.group({
      cutoffTime: ['', Validators.required],
      percentage: ['', Validators.required],
      selectedButton: ['Hours']
    });
    this.cancellationWindowConditions.push(windowGroup);
  }

  removeWindowCondition(index: number) {
    this.cancellationWindowConditions.removeAt(index);
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  selectButton(index: number, button: string) {
    this.cancellationWindowConditions.at(index).get('selectedButton')?.setValue(button);
  }

  addCancellation() {
    this.loading = true;
    let obj: any = {
      "NAME": this.cancellationForm.get('name').value,
      "DESCRIPTION": this.cancellationForm.get('description').value,
      "CANCELLATION_WINDOW_ID": Number(this.cancellationForm.get('cancellationWindow').value),
      "CANCELLATION_WINDOW_CONDITION": [],
      "SERVICE_ID": this.cancellationForm.get('serviceName').value
    };
    if (this.cancellationForm.get('cancellationWindow').value === '1') {
      obj.CANCELLATION_WINDOW_CONDITION = this.cancellationForm.get('cancellationWindowConditions').value.map((condition: any) => {
        let cutoffTime = condition.cutoffTime;
        if (condition.selectedButton === 'Days') {
          cutoffTime = Number(cutoffTime) * 24;
        }
        return {
          "CANCELLATION_FEES_ID": condition.percentage,
          "WINDOW": Number(cutoffTime)
        };
      });
    }
    if (this.data?.id) {
      obj.ID = this.data.id;
      this.cs.updateServiceCancellationPolicy(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          );
        }
      });
    } else {
      this.cs.addServiceCancellationPolicy(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          );
        }
      });
    }
  }

}
