<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Update hotel':'Create new hotel'}}</span>
        </div>
        <div>
            <button mat-button mat-dialog-close class="close-btn">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="addClientForm">
        <div mat-dialog-content class="contents" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="row" fxLayoutGap="41px" *ngIf="!!data">
                <div fxLayout="column" fxFlex="100%">
                    <div class="box-name">
                        Upload image
                    </div>
                    <div class="text-box1" fxLayout="row" style="height:auto" appFileDragDrop
                        (filesChangeEmiter)="onFileSelected($event)">
                        <div>
                            <input type="file" formControlName="image" class="doc_file" id="doc_file"
                                style="display: none" #fileInput hidden accept=".jpg,.jpeg,.png"
                                (change)="onFileSelected($event.target.files)" />
                            <mat-icon (click)="fileInput.click()" style="cursor: pointer;">upload</mat-icon>
                            <mat-error class="error"
                                *ngIf="addClientForm.controls['image'].touched && addClientForm.get('image').hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div (click)="fileInput.click()">
                            <span class="upload-text1">Click to upload </span><span class="upload-text2">
                                or drag and drop jpeg,jpg,png
                            </span>
                        </div>
                    </div>
                    <div class="file-name" (click)="preview(source_url)" *ngIf="imageName != ''"
                        style="cursor: pointer;">
                        {{imageName}}
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Select Sub-Chain
                    </div>
                    <div>
                        <mat-select class="text-box" style="font-size: 13px;" appearance="fill" formControlName="subChainName"
                            placeholder="Select">
                            <mat-option *ngFor="let chain of hotelSubChains" [value]="chain.ID">{{chain.NAME}}</mat-option>
                        </mat-select>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['subChainName'].touched && addClientForm.get('subChainName').hasError('required')">
                            Please select hotel sub-chain from the list.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Enter name">
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['name'].touched && addClientForm.get('name').hasError('required')">
                            Please enter the hotel name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['name'].errors?.minlength || addClientForm.controls['name'].errors?.maxlength || addClientForm.controls['name'].errors?.pattern">
                            Hotel name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Company
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="company" placeholder="Enter company name">
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['company'].touched && addClientForm.get('company').hasError('required')">
                            Please enter the company name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['company'].errors?.minlength || addClientForm.controls['company'].errors?.maxlength || addClientForm.controls['company'].errors?.pattern">
                            Company name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Owner
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="owner" placeholder="Enter owner name">
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['owner'].touched && addClientForm.get('owner').hasError('required')">
                            Please enter the owner name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['owner'].errors?.minlength || addClientForm.controls['owner'].errors?.maxlength || addClientForm.controls['owner'].errors?.pattern">
                            Owner name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Branch
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="branch" placeholder="Enter branch name">
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['branch'].touched && addClientForm.get('branch').hasError('required')">
                            Please enter the branch name (letters only, 2-30 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['branch'].errors?.minlength || addClientForm.controls['branch'].errors?.maxlength || addClientForm.controls['branch'].errors?.pattern">
                            Branch name should contain only letters and be between 2 and 30 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        Address
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="address" placeholder="Enter address"> </textarea>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['address'].touched && addClientForm.get('address').hasError('required')">
                            Please enter the address (letters only, 2-300 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['address'].errors?.minlength || addClientForm.controls['address'].errors?.maxlength || addClientForm.controls['address'].errors?.pattern">
                            Address should contain only letters and be between 2 and 300 characters.
                        </mat-error>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="45%">
                    <div class="box-name">
                        About
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="about"
                            placeholder="About your company"> </textarea>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['about'].touched && addClientForm.get('about').hasError('required')">
                            Please enter about (letters only, 2-300 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['about'].errors?.minlength || addClientForm.controls['about'].errors?.maxlength || addClientForm.controls['about'].errors?.pattern">
                            About should contain only letters and be between 2 and 300 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="column" fxFlex="100%">
                    <div class="box-name">
                        Description
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="description" placeholder="Describe"> </textarea>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['description'].touched && addClientForm.get('description').hasError('required')">
                            Please enter description (letters only, 2-300 characters).
                        </mat-error>
                        <mat-error class="error"
                            *ngIf="addClientForm.controls['description'].errors?.minlength || addClientForm.controls['description'].errors?.maxlength || addClientForm.controls['description'].errors?.pattern">
                            Description should contain only letters and be between 2 and 300 characters.
                        </mat-error>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="41px">
                <div fxLayout="row" fxFlex="100%">
                    <div class="box-name">
                        PMS System
                    </div>
                    <div>
                        <label class="switch" style="top: -6px">
                            <input #checkbox type="checkbox" [checked]="isPMSEnabled"
                                (change)="enablePMS(checkbox.checked)">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <mat-dialog-actions dir="rtl" class="actions">
                <button mat-stroked-button [ngClass]="this.addClientForm.invalid ? 'button' : 'save-button'"
                    [disabled]="this.addClientForm.invalid" (click)="addClient()">Save</button>
                <button mat-stroked-button class="button" style="margin-right: 18px;" (click)="cancel()">Cancel</button>
            </mat-dialog-actions>
        </div>
    </form>
</div>